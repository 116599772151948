export const currencyFormat = {
  style: 'currency',
  currencyDisplay: 'symbol',
  currency: '',
  minimumFractionDigits: '2',
  maximumFractionDigits: '2'
}

export default {
  methods: {
    /**
     * Format the given currency code
     *
     * @param {String} currencyCode     the ISO 4217 currency code
     * @return {String}                 the formatted currency or
     *                                  unchanged {@code currencyCode} if it is
     *                                  falsy
     */
    formatCurrency (currencyCode) {
      let res = currencyCode

      if (currencyCode) {
        const currencyText = Intl.NumberFormat (this.$i18n.locale, {
          style: 'currency',
          currency: currencyCode,
          currencyDisplay: 'name'
        }).formatToParts (0).find (p => p.type === 'currency')

        const currencyName =
          currencyText.value.replace (/(^|\s|-)\S/g, c => c.toUpperCase ())

        res = `${currencyName} (${currencyCode})`
      }

      return res
    },

    /**
     * Get the currency symbol for a currency code
     *
     * @param {String} currencyCode     the ISO 4217 currency code
     * @return {Array}                 Array with the first element beeing the
     *                                  symbol (or currency code in non is
     *                                  defined) and the second beeing a boolean
     *                                  telling you if the symbol should be in
     *                                  front
     */
    getSymbol (currencyCode) {
      let res = currencyCode
      let currencySymbolIndex = false

      if (currencyCode) {
        const parts = Intl.NumberFormat (this.$i18n.locale, {
          style: 'currency',
          currency: currencyCode,
          currencyDisplay: 'symbol'
        }).formatToParts (0)

        currencySymbolIndex = parts.findIndex (p => p.type === 'currency')

        const currencySymbol = parts[currencySymbolIndex]

        res = currencySymbol ? currencySymbol.value : currencyCode
      }

      return [res, currencySymbolIndex === 0]
    },

    /**
     * Format the given money amount according to the given locale.
     *
     * @param {Number} value        the amount value (may be empty)
     * @param {String} currency     the amount currency (default: '')
     * @param {Object} locale       the locale to use (default: current user
     *                              locale)
     * @return {String}             the formatted amount or original value
     *                              (if it's falsy)
     */
    formatMoneyAmount (value, currency = '', locale = this.$i18n.locale, maximumFractionDigits = '2') {
      return !Number.isNaN (value) && (currency || currency === '')
        ? new Intl.NumberFormat (locale, {
          ...{...currencyFormat, maximumFractionDigits},
          currency: currency
        }).format (value)
        : value
    }
  }
}
