<template>
  <v-slide-y-transition mode="out-in">
    <router-view/>
  </v-slide-y-transition>
</template>

<script>
  import {mapState} from 'vuex'

  export default {
    name: 'App',

    computed: {
      ...mapState ('router', ['routeTitleKey']),

      documentTitle () {
        return `${this.$t (this.routeTitleKey)} | GatewayNG`
      }
    },

    watch: {
      /**
       * set the document title if it was changed
       *
       * @param newValue    the new document title
       */
      documentTitle: {
        handler (newValue) {
          document.title = newValue
        },
        immediate: true
      }
    }
  }
</script>
