import BaseConstraint from './BaseConstraint'

/**
 * the constraint, which is satisfied if any of constraints, specified in
 * constructor is satisfied
 */
export default class AnyConstraint extends BaseConstraint {
  /**
   * create the constraint with specified constraints
   *
   * @param constraints     constraints to be checked
   */
  constructor (constraints) {
    super ({
      check () {
        return this.checkConstraints ()
      }
    })

    this.constraints = constraints
  }

  /**
   * checks whether any of constraints, specified in constructor is satisfied
   *
   * @return {boolean}      {@code true} if any of constraints, specified in
   *                        constructor is satisfied, {@code false} otherwise
   */
  checkConstraints () {
    return this.constraints.some (it => it.isSatisfied (this.user))
  }
}
