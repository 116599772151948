/**
 * get default domain properties for domain creation
 * @returns {Object}    default domain properties for domain creation
 */
function getDefaultDomainParams () {
  return {
    registryId: null,
    clientId: null,
    names: [],
    variants: [],
    language: null,
    period: 1,
    contacts: [],
    wapOverride: null,
    registrantType: null,
    publishRegistrantData: null,
    hosts: [],
    dnssecData: [],
    authInfo: null,
    providerChainType: 'default',
    providerChainSpec: null,
    maintainer: '',
    addOnFields: {},
    specificFields: {},
    priceLimit: null,
    launchPhase: null
  }
}

/**
 * default value for `editProviderChain` property
 * @type {boolean}
 */
const DefaultEditProviderChain = false

/**
 * get default value for `specificFieldData` property
 * @returns {*[]}
 */
function getSpecificFieldData () {
  return []
}

/**
 * get default value for `addOnFieldData` property
 * @returns {*[]}
 */
function getAddOnFieldData () {
  return []
}

/**
 * get default values for domain creation
 * @returns {Object}        default values for domain creation
 */
function getDefaultCreateParams () {
  return {
    domain: getDefaultDomainParams (),
    editProviderChain: DefaultEditProviderChain,
    specificFieldData: getSpecificFieldData (),
    addOnFieldData: getAddOnFieldData ()
  }
}

const LOCKED_STATE = 2
const LOCK_REQUESTED_STATE = 1
const UNLOCKED_STATE = 0
const UNLOCK_REQUESTED_STATE = 3

/**
 * Get the path to the label for the right status
 *
 * @param {string} status the status of the domain
 */
function getLockStatusLabel (status) {
  switch (status) {
    case LOCKED_STATE:
      return 'view.label.locking.status.locked'
    case UNLOCKED_STATE:
      return 'view.label.locking.status.unlocked'
    case UNLOCK_REQUESTED_STATE:
      return 'view.label.locking.status.unlockRequested'
    case LOCK_REQUESTED_STATE:
      return 'view.label.locking.status.lockRequested'
    default:
      return 'view.label.locking.status.unkown'
  }
}

function formatLauchPhase (lp) {
  return lp ? `${lp.name}${lp.subname ? '/' + lp.subname : ''}` : ''
}

export {
  getDefaultDomainParams,
  DefaultEditProviderChain,
  getSpecificFieldData,
  getAddOnFieldData,
  getDefaultCreateParams,
  LOCKED_STATE,
  LOCK_REQUESTED_STATE,
  UNLOCKED_STATE,
  UNLOCK_REQUESTED_STATE,
  getLockStatusLabel,
  formatLauchPhase
}
