/**
 * create the object, containing properties from specified store, which are
 * used in constraints for permission checks
 *
 * @param store     application store
 * @return {*}      the object, containing properties from the store,
 *                  which are used in constraints for permission checks or
 *                  {code null} if store is falsy
 */
export default store => (store
  ? {
    isAuthenticated: store.state.auth ? store.state.auth.isAuthenticated : null,
    permissions: store.state.auth ? store.getters['auth/permissions'] : [],
    hasClient: store.state.auth ? store.getters['auth/hasClient'] : null,
    clientType: store.state.auth ? store.getters['auth/actingClientType'] : null,
    operationMode: store.state.auth ? store.state.auth.operationMode : null
  }
  : null)
