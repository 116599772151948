import BaseConstraint from './BaseConstraint'

/**
 * the constraint, checking if the user is authenticated
 */
export default new BaseConstraint ({
  /**
   * checks whether the user is authenticated
   *
   * @return {Boolean|truthy|*}   {@code true} if the user is authenticated,
   *                              {@ false} otherwise
   */
  check () {
    return this.isAuthenticated ()
  }
})
