const getResultCode = responseData => responseData?.result?.[0]?.code

const isSuccess = responseData =>
  !getResultCode (responseData).startsWith ('error')

const jsonService = {
  getResultCode,
  isSuccess,

  process (response, cb, cbError, cbFinal, defaultErrorCB = () => {}) {
    const responseData = response.data
    const success = isSuccess (responseData)

    if (success) {
      if (typeof cb === 'function') {
        cb (responseData)
      }
    } else {
      if (typeof cbError === 'function') {
        cbError (responseData, defaultErrorCB)
      }
    }

    return success
  }
}

export default jsonService
