import {defaultLocale} from '@/app/i18n/locales'

const APP_LOCALE_KEY = 'appLocale'

export default {
  namespaced: true,

  state: {
    appLocale: window.localStorage.getItem (APP_LOCALE_KEY) || defaultLocale
  },

  mutations: {
    setAppLocale (state, key) {
      state.appLocale = key
      window.localStorage.setItem (APP_LOCALE_KEY, state.appLocale)
    }
  }
}
