/* Third Party */
import _merge from 'lodash/merge'

/* Components */
import Vue from 'vue'

/* Global mixins */
import CurrencyMixin from '@/app/core/mixins/CurrencyHelper'
import DatetimeMixin from '@/app/core/mixins/DatetimeHelper'

/* Filters */
import Capitalize from './filters/Capitalize/Capitalize'
import Uppercase from './filters/Uppercase/Uppercase'

Vue.mixin (_merge ({}, CurrencyMixin, DatetimeMixin))

Vue.filter ('capitalize', Capitalize)
Vue.filter ('uppercase', Uppercase)
