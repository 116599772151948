import axios from 'axios'
import errorTranslator from '@/app/services/errortranslator'
import jsonService from '@/app/services/json'

const endPointUrl = process.env.VUE_APP_API_BASE_URL || 'https://localhost:6080/rest/'

export default {
  namespaced: true,

  getters: {
    operationLink: (state, getters, rootState) => {
      /**
       * creates the GET-method link to specified BE operation
       *
       * @param op              operation to be performed
       * @param params          operation parameters
       *
       * @return {string}       generated URL, which can be used as link HREF
       */
      return ({op, params}) => {
        const jwt = rootState.auth.jwtString

        const request = {
          request: {
            op: op
          },
          ...params
        }

        const req = encodeURIComponent (JSON.stringify (request))
        return `${endPointUrl}${op}?jwt=${jwt}&request=${req}`
      }
    }
  },

  actions: {

    /**
     * handle the given request (in form of a promise)
     *
     * @param context     the context object which exposes the set of
     *                    methods/properties on the store instance
     * @param request     the request (promise) to be handled
     * @param cb          the optional call back function, which will be
     *                    called if the request was resolved successfully.
     *                    Resolved data will be passed to the function as
     *                    a parameter.
     * @param cbError     the optional call back function, which will be
     *                    called if the request was resolved, but the
     *                    fetching data containing errors. This data will
     *                    be passed to the function as a parameter.
     * @param cbFinal     the optional call back function, which will be
     *                    called after data fetching regardless of the
     *                    outcome. Fetched data will be passed to the
     *                    function as a parameter.
     * @param cbCatch     the optional call back function, which will be
     *                    called if the request was rejected. An rejection
     *                    error will be passed to the function as a parameter.
     *
     * @return Object     the operation `success` flag and the data from REST
     *                    response (if successfully fetched), or error message
     *                    otherwise ({success, data | errorMessage})
     */

    async handleRequest ({commit, dispatch}, {
      request,
      cb,
      cbError = (data) => {
        commit ('notification/setError', errorTranslator (data), {root: true})
      },
      cbFinal,
      cbCatch = e => {
        const response = e.response
        const errorMessage = response?.data
          ? errorTranslator (response.data)
          : e.message

        commit ('notification/setError', errorMessage, {root: true})
      }
    }) {
      const result = {success: false}

      const defaultErrorCB = data => {
        commit ('notification/setError', errorTranslator (data), {root: true})
      }

      try {
        const response = await request

        result.success =
          jsonService.process (response, cb, cbError, () => {}, defaultErrorCB)

        result.data = response.data
      } catch (e) {
        if (typeof cbCatch === 'function') {
          cbCatch (e)
        } else {
          throw new Error (e)
        }

        result.errorMessage = e.message
      }

      if (typeof cbFinal === 'function') {
        cbFinal (result)
      }

      return result
    },

    /**
     * dispatch/execute the given request by sending it to the REST endpoint
     *
     * @param context     the context object which exposes the set of
     *                    methods/properties on the store instance
     * @param request     the request to be dispatched
     * @param cb          the optional call back function, which will be
     *                    called after successful data fetching. This data
     *                    will be passed to the function as a parameter.
     * @param cbError     the optional call back function, which will be
     *                    called after data fetching if it was erroneous.
     *                    This data will be passed to the function as a
     *                    parameter.
     * @param cbFinal     the optional call back function, which will be
     *                    called after data fetching regardless of the
     *                    outcome. Fetched data will be passed to the
     *                    function as a parameter.
     * @param cbCatch     the optional call back function, which will be
     *                    called if data fetching was erroneous. An error
     *                    will be passed to the function as a parameter.
     *
     * @return Object     the operation `success` flag and the data from REST
     *                    response (if successfully fetched), or error message
     *                    otherwise
     */

    dispatchRequest ({commit, dispatch}, {request, ...callbacks}) {
      const endPoint = endPointUrl + request.request.op

      console.log (`dispatching request to '${endPoint}'`, request)

      return dispatch (
        'handleRequest',
        {request: axios.post (endPoint, request), ...callbacks})
    },

    /**
     * execute the given operation with specified parameters by sending
     * a request to the REST endpoint
     *
     * @param dispatch    the function from action context to be used
     *                    to call other actions
     * @param op          operation to be performed
     * @param params      operation parameters
     * @param cb          the optional call back function, which will be
     *                    called after successful data fetching. This data
     *                    will be passed to the function as a parameter.
     * @param cbError     the optional call back function, which will be
     *                    called after data fetching if it was erroneous.
     *                    This data will be passed to the function as a
     *                    parameter.
     * @param cbFinal     the optional call back function, which will be
     *                    called after data fetching regardless of the
     *                    outcome. Fetched data will be passed to the
     *                    function as a parameter.
     * @param cbCatch     the optional call back function, which will be
     *                    called if data fetching was erroneous. An error
     *                    will be passed to the function as a parameter.
     *
     * @return Object     the data from REST response (if successfully
     *                    fetched), {@code undefined} otherwise
     */
    async fetchData ({dispatch}, {op, params, ...callbacks}) {
      const request = {
        request: {
          op: op
        },
        ...params
      }

      const {data} = await dispatch ('dispatchRequest', {request, ...callbacks})

      return data
    }

  }
}
