/**
 * get default zone properties
 * @returns {Object}      default zone properties
 */
function getDefaultZoneData () {
  return {
    clientId: null,
    userDefinedId: '',
    domainName: '',
    notifications: [],
    providerChainSpec: '',
    providerChainType: 'default',
    nameServers: [],
    resourceRecords: [],
    sourceData: null,
    dnssecEnabled: false,
    autoDomainLinkage: false
  }
}

/**
 * default value for `editProviderChain` property
 * @type {boolean}
 */
const DefaultEditProviderChain = false

/**
 * get default parameter for a zone create
 * @type {Object}     default parameter for a zone create
 */
function getDefaultCreateParams () {
  return {
    zone: getDefaultZoneData (),
    type: 'literal',
    editProviderChain: DefaultEditProviderChain,
    savedResourceRecords: [],
    showTsig: false,
    savedTsigData: {
      tsigAlg: 'hmac-md5',
      tsigName: '',
      tsigSecret: ''
    },
    savedSourceData: {
      address: ''
    }
  }
}

const DefaultTsigAlgs = ['hmac-sha256', 'hmac-sha1', 'hmac-md5']

const DefaultNotificationTypes = [
  'user-deployment',
  'system-deployment',
  'transfer-failure',
  'general-error'
]

export {
  getDefaultZoneData,
  DefaultEditProviderChain,
  getDefaultCreateParams,
  DefaultTsigAlgs,
  DefaultNotificationTypes
}
