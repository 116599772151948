const localesString = process.env.VUE_APP_I18N_LOCALES

const locales = localesString
  ? JSON.parse (localesString)
  : [
    'en',
    'de'
  ]

module.exports = {
  locales,
  defaultLocale: process.env.VUE_APP_I18N_LOCALE || locales[0]
}
