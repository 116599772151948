export default {
  namespaced: true,

  state: {
    hasMessage: false,
    messageType: '',
    message: '',
    events: [],
    showEventMenu: false
  },

  mutations: {
    // set a new notification
    setError (state, message) {
      console.log ('setting notification')
      state.events = [createEvent ('error', message, null), ...state.events]
      setNotificationMessage (state, message, 'error')
      console.log ('setting notification ' + message)
    },

    setWarning (state, message) {
      setNotificationMessage (state, message, 'warning')
    },

    setInfo (state, message) {
      setNotificationMessage (state, message, 'info')
    },

    setSuccess (state, message) {
      setNotificationMessage (state, message, 'success')
    },

    setMessage (state, message, type) {
      setNotificationMessage (state, message, type)
    },

    addEvent (state, {type, message, objects}) {
      state.events = [createEvent (type, message, objects), ...state.events]
    },

    // reset (remove) all currently set errors
    resetMessage (state) {
      state.hasMessage = false
      state.messageType = ''
      state.message = ''
    },

    setShowEventMenu (state, value) {
      state.showEventMenu = value
    }
  }
}

function setNotificationMessage (state, message, type) {
  state.messageType = type
  state.message = message
  state.hasMessage = true
}

function createEvent (type, message, objects) {
  const time = new Date ()
  return {id: time.getTime (), message, type, createdAt: time, objects}
}
