import BaseConstraint from './BaseConstraint'

/**
 * this constraint can be used when the decision should be made
 * according to the fact whether the user belongs to some client or not
 */
export default class ClientExistenceConstraint extends BaseConstraint {
  /**
   * create new constraint, which will check whether the user's
   * 'hasClient' flag is equal to the specified parameter
   * {@code clientMustExist}
   *
   * @param clientMustExist         the value, which will be compared to
   *                                user's 'hasClient' flag
   */
  constructor (clientMustExist = true) {
    super ({
      check () {
        return this.clientMustExist === this.user.hasClient
      }
    })

    this.clientMustExist = clientMustExist
  }
}
