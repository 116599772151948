/**
 * Formats date to iso format 'YYYY-MM-DD HH:mm'
 *
 * @param {Date|Number|String} input      date value to be formatted
 */
export const formatDate = (input, omitUTC = false) => {
  let formatted = ''

  if (input) {
    const date = new Date (input)
    const dateStr = date.toISOString ()
    formatted = `${dateStr.substr (0, 10)} ${dateStr.substr (11, 5)}${omitUTC ? '' : ' UTC'}`
  }

  return formatted
}

/**
 * Formats time to iso format 'HH:mm'
 *
 * @param {Date|Number|String} input      date value to be formatted
 */
export const formatTime = (input, omitUTC = false) => {
  let formatted = ''

  if (input) {
    const date = new Date (input)
    const dateStr = date.toISOString ()
    formatted = `${dateStr.substr (11, 5)}${omitUTC ? '' : ' UTC'}`
  }

  return formatted
}

/**
 * Formats date to iso format 'YYYY-MM-DD'
 *
 * @param {Date|Number|String} input      date value to be formatted
 */
export const formatDateShort = (input, omitUTC = false) => {
  let formatted = ''

  if (input) {
    const date = new Date (input)
    formatted = `${date.toISOString ().substr (0, 10)}${omitUTC ? '' : ' UTC'}`
  }

  return formatted
}

/**
 * Formats date to format 'YYYY-MM'
 *
 * @param {Date|Number|String} input      date value to be formatted
 */
export const formatDateMonth = (input, omitUTC = false) => {
  let formatted = ''

  if (input) {
    const date = new Date (input)
    formatted = `${date.toISOString ().substr (0, 7)}${omitUTC ? '' : ' UTC'}`
  }

  return formatted
}

export function durationToDHM (duration) {
  let hour, minute

  const seconds = duration.seconds
  minute = Math.floor (seconds / 60)
  // seconds = seconds % 60
  hour = Math.floor (minute / 60)
  minute = minute % 60
  const day = Math.floor (hour / 24)
  hour = hour % 24
  return {
    day: day,
    hour: hour,
    minute: minute
  }
}

export default {
  methods: {
    formatDate,
    formatDateShort,
    formatTime,
    formatDateMonth,
    formatDuration (duration) {
      const dhm = durationToDHM (duration)
      const parts = []

      Object.keys (dhm).forEach (it => {
        const count = dhm[it]

        if (count) {
          parts.push (this.$tc (`duration.${it}`, count, {count}))
        }
      })

      return parts.join (' ')
    }
  }
}
