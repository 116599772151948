import Vue from 'vue'
import VueI18n from 'vue-i18n'
import _merge from 'lodash/merge'

import {locales, defaultLocale} from './locales'

Vue.use (VueI18n)

function loadLocaleMessages () {
  const localeMessages = require.context ('.', false, /\.?\/?.*?([^.]+)\.yaml$/i)
  const messages = {}

  localeMessages.keys ().forEach (key => {
    const matched = key.match (/\.?\/?.*?([^.]+)\.yaml$/i)

    if (matched?.length > 1) {
      // _merge (messages, localeMessages (key))
      // next lines supposed to be used without custom yaml loader
      const locale = matched[1]

      if (locales.includes (locale)) {
        _merge (messages, {[locale]: localeMessages (key)})
      }
    }
  })

  return messages
}

export default new VueI18n ({
  silentFallbackWarn: true,

  locale: process.env.VUE_APP_I18N_LOCALE || defaultLocale,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || defaultLocale,

  messages: loadLocaleMessages ()
})
