import Vue from 'vue'
import VueAxios from 'vue-axios'
import {VueAuthenticate} from 'vue-authenticate'
import axios from 'axios'
import store from '../store'

Vue.use (VueAxios, axios)

const vueAuthInstance = new VueAuthenticate (Vue.prototype.$http, {
  baseUrl: process.env.VUE_APP_AUTH_API_URL || 'https://localhost:6080/rest/',
  tokenPath: 'token',
  loginUrl: 'login',

  bindRequestInterceptor () {
    const tokenHeader = this.options.tokenHeader

    this.$http.interceptors.request.use ((config) => {
      if (this.isAuthenticated ()) {
        config.headers[tokenHeader] = [
          this.options.tokenType, this.getToken ()
        ].join (' ')
      } else {
        delete config.headers[tokenHeader]
      }
      return config
    })

    this.$http.interceptors.response.use ((response) => {
      // update last API access time
      store.commit ('auth/updateLastApiAccessTime')

      const oldToken = this.getToken ()
      this.setToken (response)

      // update authentication in case new JWT was received in response
      if (oldToken !== this.getToken ()) {
        store.commit ('auth/updateAuthentication')
      }

      return response
    })
  }
})

export default vueAuthInstance
