export const ALLOWED = 'allowed'
export const REQUIRED = 'required'
export const DISALLOWED = 'disallowed'

export const IDN_REQUIREMENT = {
  REQUIRED: 'Required',
  DISALLOWED: 'Disallowed',
  REQUIRED_AND_UPDATABLE: 'RequiredAndUpdatable'
}

export const CONTACT_ADDRESS_TYPES = {
  INT: 'int',
  LOC: 'loc'
}

/**
 * create empty registry metadata
 *
 * @return {Object}   empty registry metadata
 */
function getDefaultRegistryMetaData () {
  return {
    icannRegulated: undefined,
    launchPhases: [],
    launchPhaseRequired: false,
    contactRoles: [],
    idn: '',
    idnLanguage: '',
    idnLanguageList: undefined,
    idnLangRequirement: undefined,
    defaultIdnTag: undefined,
    dnskeyData: '',
    dsData: '',
    baseNames: [],
    variants: undefined,
    thin: undefined,
    registrantPrivacyAllowed: undefined,
    allocationTokenAllowed: undefined,
    metaDataSupported: undefined,
    authInfoSettable: undefined,
    contactAuthInfoSupported: undefined,
    transferRequiresAuthInfo: undefined,
    requiredContactFields: undefined,
    optionalContactFields: undefined,
    prohibitedContactFields: undefined,
    promotionCodeSupported: undefined,
    transferMode: undefined,
    contactSupport: undefined,
    hostSupport: undefined,
    supportedContactAddress: undefined,
    mandatoryContactAddress: undefined
  }
}

export default {
  namespaced: true,

  state: {
    registryMetaData: getDefaultRegistryMetaData (),
    idnTags: null
  },

  getters: {
    isIcannRegulated (state) {
      return state.registryMetaData.icannRegulated
    },

    getLaunchPhases (state) {
      return state.registryMetaData.launchPhases
    },

    isLaunchPhaseRequired (state) {
      return state.registryMetaData.launchPhaseRequired
    },

    getContactRoles (state) {
      return state.registryMetaData.contactRoles
    },

    isIdnAllowed (state) {
      return state.registryMetaData.idn === ALLOWED
    },

    isIdnRequired (state) {
      return state.registryMetaData.idn === REQUIRED
    },

    isIdnDisallowed (state) {
      return state.registryMetaData.idn === ALLOWED
    },

    getIdnLanguages (state) {
      return state.registryMetaData.idnLanguageList
    },

    isIdnLangRequired (state) {
      return state.registryMetaData.idnLangRequirement === IDN_REQUIREMENT.REQUIRED
    },

    isIdnLangDisallowed (state) {
      return state.registryMetaData.idnLangRequirement === IDN_REQUIREMENT.DISALLOWED
    },

    isIdnLangRequiredAndUpdatable (state) {
      return state.registryMetaData.idnLangRequirement === IDN_REQUIREMENT.REQUIRED_AND_UPDATABLE
    },

    getDefaultIdnTag (state) {
      return state.registryMetaData.defaultIdnTag
    },

    isDnskeyDataAllowed (state) {
      return state.registryMetaData.dnskeyData === ALLOWED
    },

    isDnskeyDataRequired (state) {
      return state.registryMetaData.dnskeyData === REQUIRED
    },

    isDnskeyDataDisallowed (state) {
      return state.registryMetaData.dnskeyData === DISALLOWED
    },

    isDsDataAllowed (state) {
      return state.registryMetaData.dsData === ALLOWED
    },

    isDsDataRequired (state) {
      return state.registryMetaData.dsData === REQUIRED
    },

    isDsDataDisallowed (state) {
      return state.registryMetaData.dsData === DISALLOWED
    },

    getBaseNames (state) {
      return state.registryMetaData.baseNames
    },

    isVariantsAllowed (state) {
      return state.registryMetaData.variants
    },

    isThinRegistry (state) {
      return state.registryMetaData.thin
    },

    isRegistrantPrivacyAllowed (state) {
      return state.registryMetaData.registrantPrivacyAllowed
    },

    isAllocationTokenAllowed (state) {
      return state.registryMetaData.allocationTokenAllowed
    },

    isMetaDataSupported (state) {
      return state.registryMetaData.metaDataSupported
    },

    isAuthInfoSettable (state) {
      return state.registryMetaData.authInfoSettable
    },

    isContactAuthInfoSupported (state) {
      return state.registryMetaData.contactAuthInfoSupported
    },

    isTransferRequiresAuthInfo (state) {
      return state.registryMetaData.transferRequiresAuthInfo
    },

    getRequiredContactFields (state) {
      return state.registryMetaData.requiredContactFields
    },

    getOptionalContactFields (state) {
      return state.registryMetaData.optionalContactFields
    },

    getProhibitedContactFields (state) {
      return state.registryMetaData.prohibitedContactFields
    },

    isPromotionCodeSupported (state) {
      return state.registryMetaData.promotionCodeSupported
    },

    getTransferMode (state) {
      return state.registryMetaData.transferMode
    },

    hasContactSupport (state) {
      return state.registryMetaData.contactSupport
    },

    hasHostSupport (state) {
      return state.registryMetaData.hostSupport
    },

    getIdnTags (state) {
      return state.idnTags
    },

    getMandatoryContactAddress (state) {
      return state.registryMetaData.mandatoryContactAddress
    },

    getSupportedContactAddress (state) {
      return state.registryMetaData.supportedContactAddress
    }

  },

  actions: {
    loadIdnTags ({dispatch, commit}, langs) {
      return new Promise ((resolve, reject) => {
        dispatch ('request/fetchData', {
          op: 'registry/load-idn-tags',
          params: {
            strings: langs
          },
          cb: data => {
            const unique = []
            data.tags.forEach ((m) => {
              if (unique.findIndex ((i) => i.tag === m.tag) === -1) { unique.push (m) }
            })

            commit ('setIdnTags', unique)
            resolve (data)
          },
          cbError: reject,
          cbCatch: reject
        }, {root: true})
      })
    }
  },

  mutations: {
    resetRegistryMetaData (state) {
      state.registryMetaData = getDefaultRegistryMetaData ()
    },
    setIcannRegulated (state, data) {
      state.registryMetaData.icannRegulated = data
    },

    setLaunchPhases (state, data) {
      state.registryMetaData.launchPhases = data
    },

    setLaunchPhaseRequired (state, data) {
      state.registryMetaData.launchPhaseRequired = data
    },

    setContactRoles (state, data) {
      state.registryMetaData.contactRoles = data
    },

    setIdnRequirementSpec (state, data) {
      state.registryMetaData.idn = data
    },

    setIdnLangRequirementSpec (state, data) {
      state.registryMetaData.idnLanguage = data
    },

    setIdnLangRequirement (state, data) {
      state.registryMetaData.idnLangRequirement = data
    },

    setIdnLanguages (state, data) {
      state.registryMetaData.idnLanguageList = data
    },

    setDefaultIdnTag (state, data) {
      state.registryMetaData.defaultIdnTag = data
    },

    setDnskeyDataRequirementSpec (state, data) {
      state.registryMetaData.dnskeyData = data
    },

    setDsDataRequirementSpec (state, data) {
      state.registryMetaData.dsData = data
    },

    setBaseNames (state, data) {
      state.registryMetaData.baseNames = data
    },

    setVariantsAllowed (state, data) {
      state.registryMetaData.variants = data
    },

    setThinRegistry (state, data) {
      state.registryMetaData.thin = data
    },

    setRegistrantPrivacyAllowed (state, data) {
      state.registryMetaData.registrantPrivacyAllowed = data
    },

    setAllocationTokenAllowed (state, data) {
      state.registryMetaData.allocationTokenAllowed = data
    },

    setMetaDataSupported (state, data) {
      state.registryMetaData.metaDataSupported = data
    },

    setAuthInfoSettable (state, data) {
      state.registryMetaData.authInfoSettable = data
    },

    setContactAuthInfoSupported (state, data) {
      state.registryMetaData.contactAuthInfoSupported = data
    },

    setTransferRequiresAuthInfo (state, data) {
      state.registryMetaData.transferRequiresAuthInfo = data
    },

    setRequiredContactFields (state, data) {
      state.registryMetaData.requiredContactFields = data
    },

    setOptionalContactFields (state, data) {
      state.registryMetaData.optionalContactFields = data
    },

    setProhibitedContactFields (state, data) {
      state.registryMetaData.prohibitedContactFields = data
    },

    setPromotionCodeSupported (state, data) {
      state.registryMetaData.promotionCodeSupported = data
    },

    setTransferMode (state, data) {
      state.registryMetaData.transferMode = data
    },

    setContactSupport (state, data) {
      state.registryMetaData.contactSupport = data
    },

    setHostSupport (state, data) {
      state.registryMetaData.hostSupport = data
    },

    setMandatoryContactAddress (state, data) {
      state.registryMetaData.mandatoryContactAddress = data
    },

    setSupportedContactAddress (state, data) {
      state.registryMetaData.supportedContactAddress = data
    },

    setIdnTags (state, data) {
      let arr = data

      if (Array.isArray (data) && data.length) {
        arr = data.slice ()

        arr.sort ((a, b) => {
          const nameA = a.tag.toUpperCase ()
          const nameB = b.tag.toUpperCase ()
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
      }

      state.idnTags = arr
    }

  }
}
