import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import auth from './modules/auth'
import cache from './modules/cache'
import create from './modules/create'
import filter from './modules/filter'
import layout from './modules/layout'
import locale from './modules/locale'
import meta from './modules/meta'
import notification from './modules/notification'
import request from './modules/request'
import router from './modules/router'

import createLogger from 'vuex/dist/logger'

Vue.use (Vuex)

const debug = process.env.NODE_ENV !== 'production'

const persistedState = createPersistedState ({
  paths: [
    'auth.userLoginData',
    'auth.userPermissions',
    'auth.clientPermissions',
    'auth.visibleClients',

    'auth.effectiveUserData',
    'auth.effectiveUserPermissions',
    'auth.effectiveUserClientPermissions',

    'auth.effectiveClientData',

    'auth.lastApiAccessTime'
  ]
})

export default new Vuex.Store ({
  modules: {
    auth,
    cache,
    create,
    filter,
    locale,
    layout,
    meta,
    notification,
    request,
    router
  },
  strict: debug,
  plugins: debug ? [createLogger ({}), persistedState] : [persistedState]
})
