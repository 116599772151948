export default {
  namespaced: true,

  state: {
    routeTitleKey: '',
    desiredRoute: {}
  },

  mutations: {
    setRouteTitleKey (state, key) {
      state.routeTitleKey = key
      console.log ('setting route title key to', state.routeTitleKey)
    },

    setDesiredRoute (state, route) {
      state.desiredRoute = route
      console.log ('setting desired route name to', state.desiredRoute)
    },

    resetDesiredRoute (state) {
      state.desiredRoute = {}
      console.log ('resetting desired route name')
    }
  },

  actions: {
    redirectAfterLogin ({state, commit}, router) {
      let redirectionRoute = {name: 'dashboard'}

      if (state.desiredRoute.name) {
        redirectionRoute = state.desiredRoute
        commit ('resetDesiredRoute')
      }

      router.replace (redirectionRoute)
    }
  }
}
