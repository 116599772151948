/**
 *  possible values for contact policy
 *  @type {Array.<String>}
 */
const CONTACT_POLICIES = [
  'Restrictive',
  'Permissive'
]

/**
 * get default contact address object
 * @returns {Object}  default contact address object
 */
function getDefaultContactAddress () {
  return {
    name: null,
    organization: null,
    street: [],
    city: null,
    postalCode: null,
    stateOrProvince: null,
    countryCode: null
  }
}

/**
 * get default contact properties for contact creation
 * @returns {Object}    default contact properties for contact creation
 */
function getDefaultContactParams () {
  return {
    registryId: null,
    clientId: null,
    phoneNumber: null,
    phoneNumberExt: null,
    faxNumber: null,
    faxNumberExt: null,
    emailAddress: null,
    handle: null,
    authInfo: null,
    providerChainType: 'default',
    providerChainSpec: null,
    addresses: {
      loc: getDefaultContactAddress (),
      int: getDefaultContactAddress ()
    },
    wapOverride: false,
    policy: null,
    maintainer: '',
    addOnFields: {},
    specificFields: {}
  }
}

/**
 * default value for `editProviderChain` property
 * @type {boolean}
 */
const DefaultEditProviderChain = false

/**
 * get default value for `specificFieldData` property
 * @returns {*[]}
 */
function getSpecificFieldData () {
  return []
}

/**
 * get default value for `addOnFieldData` property
 * @returns {*[]}
 */
function getAddOnFieldData () {
  return []
}

/**
 * get default values for contact creation
 * @returns {Object}      default values for contact creation
 */
function getDefaultCreateParams () {
  return {
    contact: getDefaultContactParams (),
    editProviderChain: DefaultEditProviderChain,
    specificFieldData: getSpecificFieldData (),
    addOnFieldData: getAddOnFieldData ()
  }
}

/**
 * Construct a data object for being passed to the contact card component
 * as a parameter.
 *
 * @param {Object} contact          the contact to read the data from
 * @param {String} role             the contact role
 * @param {Boolean} wapVerified     whether the contact's e-mail address
 *                                  has been verified in the sense of
 *                                  ICANN's Whois Accuracy Program (WAP)
 * @return {Object}                 the contact data object
 */
function createContactDataObject (contact, role, wapVerified) {
  const address = contact.addresses.loc.inUse
    ? contact.addresses.loc
    : contact.addresses.int

  const conditionalProps = contact.deleted
    ? {
      status: 'deleted',
      link: {
        name: 'contact.view.version',
        params: {
          vid: contact.versionId
        }
      }
    }
    : {
      status: 'active',
      link: {
        name: 'contact.view',
        params: {
          handle: contact.handle,
          registry: contact.registryId
        }
      }
    }

  return {
    value: contact.handle,
    registry: contact.registryId,
    role,
    wapVerified: wapVerified,
    ...conditionalProps,
    title: address.title,
    name: address.name,
    email: contact.emailAddress,
    organization: address.organization,
    street: address.street.join (', '),
    postalCode: address.postalCode,
    city: address.city,
    province: address.stateOrProvince,
    country: address.countryCode
  }
}

export {
  CONTACT_POLICIES,
  getDefaultContactAddress,
  getDefaultContactParams,
  DefaultEditProviderChain,
  getSpecificFieldData,
  getAddOnFieldData,
  getDefaultCreateParams,
  createContactDataObject
}
