import i18n from '@/app/i18n'

const errorTranslator = function (errorObj) {
  return errorObj.result.map (handle).join ('\n')
}

const handle = function (error) {
  // '/' -> '.',
  // e.g. 'error/server/failure' -> 'error.server.failure'
  const code = error.code.replace (/\//g, '.')
  const params = translateParams (error.params)
  const msg = i18n.t (code, params)

  return typeof msg === 'string' && msg !== code
    ? msg
    : error.msg || i18n.t ('error.generic')
}

/**
 * translate known error parameters
 *
 * @param params    parameters to be translated
 */
const translateParams = function (params) {
  const translatedParams = {}

  for (const key in params) {
    if (params.hasOwnProperty (key)) {
      let translationKeySuffix = ''
      const param = params[key]

      switch (key) {
        case 'operation':
        case 'foundOperation':
        case 'expectedOperation':
          translationKeySuffix = 'operation'
          break

        case 'property':
        case 'actualType':
        case 'expectedType':
          translationKeySuffix = 'property'
          break

        case 'type':
          translationKeySuffix = 'type'
          break
      }

      translatedParams[key] = translationKeySuffix
        ? i18n.t (`error.${translationKeySuffix}.${param}`)
        : param
    }
  }

  return translatedParams
}

export const INVALID_VALUE_ERROR_CODE = 'error/datamgmt/value'

export const PASSWORD_PROPERTY = 'password'

export default errorTranslator
