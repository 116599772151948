function getDefaultState () {
  return {
    contactSearchQuery: null,
    domainSearchQuery: null,
    applicationSearchQuery: null,
    hostSearchQuery: null,
    zoneSearchQuery: null,
    bookEntrySearchQuery: null,
    invoiceSearchQuery: null,
    emailSearchQuery: null,
    tariffSearchQuery: null
  }
}

export default {
  namespaced: true,

  state: getDefaultState (),

  mutations: {
    resetState (state) {
      Object.assign (state, getDefaultState ())
    },

    setContactSearchQuery (state, query) {
      state.contactSearchQuery = query
    },

    setDomainSearchQuery (state, query) {
      state.domainSearchQuery = query
    },

    setApplicationSearchQuery (state, query) {
      state.applicationSearchQuery = query
    },

    setHostSearchQuery (state, query) {
      state.hostSearchQuery = query
    },

    setZoneSearchQuery (state, query) {
      state.zoneSearchQuery = query
    },

    setBookEntrySearchQuery (state, query) {
      state.bookEntrySearchQuery = query
    },

    setInvoiceSearchQuery (state, query) {
      state.invoiceSearchQuery = query
    },

    setEmailSearchQuery (state, query) {
      state.emailSearchQuery = query
    },

    setTariffSearchQuery (state, query) {
      state.tariffSearchQuery = query
    }
  }
}
