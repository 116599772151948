/**
 * get default host properties
 * @returns {Object}      default host properties
 */
function getDefaultHostData () {
  return {
    ips: [],
    domainName: '',
    registryId: null,
    clientId: '',
    providerChainType: 'default',
    providerChainSpec: '',
    addOnFields: {}
  }
}

/**
 * default value for `editProviderChain` property
 * @type {boolean}
 */
const DefaultEditProviderChain = false

/**
 * get default value for `addOnFieldData` property
 * @returns {*[]}
 */
function getAddOnFieldData () {
  return []
}

/**
 * get default parameter for a host create
 * @returns {Object}      default parameter for a host create
 */
function getDefaultCreateParams () {
  return {
    host: getDefaultHostData (),
    editProviderChain: DefaultEditProviderChain,
    addOnFieldData: getAddOnFieldData ()
  }
}

export {
  getDefaultHostData,
  DefaultEditProviderChain,
  getAddOnFieldData,
  getDefaultCreateParams
}
