import _memoize from 'lodash/memoize'

import {formatClient} from '@/app/core/mixins/ClientNameFormatter'

// use `Map` to enable cache iteration
_memoize.Cache = Map

const TTL = 30 * 60 * 1000 // 30 min
const fetchedAtKeyName = '_fetchedAt'
const fetchingKeyName = '_fetching'
const optimisticFetchingTime = 50 // check if fetched frequency (milliseconds)

export function formatLabel (d, cutTlds = 0) {
  const tlds = d.tlds && d.tlds.length
    ? cutTlds > 0
      ? ` [${d.tlds.slice (0, cutTlds).join (', ')}${d.tlds.length > cutTlds ? ', ...' : ''}]`
      : ` [${d.tlds.join (', ')}]`
    : ''
  return `${d.label} [${d.id || d.label}]${tlds}`
}

/**
 * memoise resolver, maintaining the TTL (Time-To-Live) of cached values
 *
 * @param property              module state property for which this resolver
 *                              will be used (needed to reference the cache map)
 * @return {function(*=): *}    the resolver function
 */
function ttlResolver (property) {
  /**
   * resolver determines the cache key for storing the result based on the
   * arguments provided to the memoized function. And checks Time-To-Live
   * (TTL) for cache entries
   *
   * @param key     the client id (1-st argument of function above)
   * @return {*}    the client id
   */
  return function (key) {
    const cache = this[property].cache
    const creationTimeKey = `_t-${key}`
    const now = Date.now ()

    if (cache.has (creationTimeKey)) {
      const age = now - cache.get (creationTimeKey)

      if (age > TTL) {
        cache.delete (key)
        cache.delete (creationTimeKey)
      }
    } else {
      cache.set (creationTimeKey, now)
    }

    return key
  }
}

/**
 * store registry type data, fetched from server into given cache
 *
 * @param dispatch          function to be used for calling store actions
 * @param cache             map, used to store fetched registry type data
 * @return {Promise<void>}  promise, resolving into the object, which keys
 *                          are registry type IDs and the values are the
 *                          registry type names
 */
async function cacheRegistryTypeData (dispatch, cache) {
  let registryTypeData = {}

  await dispatch ('request/fetchData', {
    op: 'registry/loadData',
    cb: data => {
      registryTypeData = data.registryData
    }
  }, {root: true})

  const now = Date.now ()

  for (const prop in registryTypeData) {
    if (registryTypeData.hasOwnProperty (prop)) {
      cache.set (prop, registryTypeData[prop])
      cache.set (`_t-${prop}`, now)
    }
  }

  cache.set (fetchedAtKeyName, now)

  return registryTypeData
}

/**
 * store registry type data, fetched from server into given cache
 *
 * @param dispatch          function to be used for calling store actions
 * @param cache             map, used to store fetched registry type data
 * @return {Promise<void>}  promise, resolving into the object, which keys
 *                          are registry type IDs and the values are the
 *                          registry type names
 */
async function cacheDomainStates (dispatch, cache) {
  let domainStates = {}

  await dispatch ('request/fetchData', {
    op: 'domain/listStates',
    cb: data => {
      domainStates = data.strings
    }
  }, {root: true})

  const now = Date.now ()

  cache.set ('domainStates', domainStates)
  cache.set ('_t-domainStates', now)

  cache.set (fetchedAtKeyName, now)

  return domainStates
}

/**
 * get registry type data, fetched from server or taken from local cache
 *
 * @param dispatch          function to be used for calling store actions
 * @param cache             map, used to store/retrieve registry type data
 * @return {Promise<void>}  promise, resolving into the object, which keys
 *                          are registry type IDs and the values are the
 *                          registry type names
 */
async function getCachedRegistryTypeData (dispatch, cache) {
  let registryTypeData = {}
  const now = Date.now ()

  const isCacheValid = cache.has (fetchedAtKeyName) &&
    (now - cache.get (fetchedAtKeyName) < TTL)

  if (!isCacheValid) {
    if (!cache.has (fetchingKeyName)) {
      // fetch the data from BE
      cache.set (fetchingKeyName, now)
      registryTypeData = await cacheRegistryTypeData (dispatch, cache)
      cache.delete (fetchingKeyName)
    } else {
      // wait for optimisticFetchingTime ms
      await (async ms => new Promise (resolve =>
        setTimeout (resolve, ms)
      )) (optimisticFetchingTime)
      // try to get fetched data again
      registryTypeData = await getCachedRegistryTypeData (dispatch, cache)
    }
  } else {
    cache.forEach ((value, key) => {
      // eliminate retrieving of service map entries like
      // {_fetchedAt: 123456789} (cache creation time)
      // {_tXXX: 123456789} (entry creation time for XXX)
      // {null: <Promise>} or {201: <Promise>} (the reason for the appearance
      // of such entries is supposed to be automatically addition of unknown
      // entries to the cache by _.memoize; e.g. if trying to resolve the name
      // of non-existing/obsolete registry)
      if (typeof key === 'string' && key.charAt (0) !== '_' && !value.then) {
        registryTypeData[key] = value
      }
    })
  }

  return registryTypeData
}

/**
 * get registry type data, fetched from server or taken from local cache
 *
 * @param dispatch          function to be used for calling store actions
 * @param cache             map, used to store/retrieve registry type data
 * @return {Promise<void>}  promise, resolving into the object, which keys
 *                          are registry type IDs and the values are the
 *                          registry type names
 */
async function getCachedDomainStates (dispatch, cache) {
  let states = []
  const now = Date.now ()

  const isCacheValid = cache.has (fetchedAtKeyName) &&
    (now - cache.get (fetchedAtKeyName) < TTL)

  if (!isCacheValid) {
    if (!cache.has (fetchingKeyName)) {
      // fetch the data from BE
      cache.set (fetchingKeyName, now)
      states = await cacheDomainStates (dispatch, cache)
      cache.delete (fetchingKeyName)
    } else {
      // wait for optimisticFetchingTime ms
      await (async ms => new Promise (resolve =>
        setTimeout (resolve, ms)
      )) (optimisticFetchingTime)
      // try to get fetched data again
      states = await getCachedDomainStates (dispatch, cache)
    }
  } else {
    states = cache.get ('domainStates')
  }

  return states
}

export default {
  namespaced: true,

  state: {
    getCachedClientName: _memoize (
      /**
       * get the client name by it's ID
       *
       * @param id                client ID
       * @param rootState         store root state
       * @param dispatch          function to be used for calling store actions
       * @return {Promise<*>}     promise, resolving into client name
       *                          (may be {@code undefined})
       */
      async (id, {rootState, dispatch}) => {
        let clientName

        if (id) {
          const userLoginData = rootState.auth.userLoginData
          const userClientId = userLoginData.clientId

          if (userClientId === id) {
            clientName = userLoginData.clientName
          } else {
            await dispatch ('request/fetchData', {
              op: 'client/loadName',
              params: {id},
              cb: data => {
                clientName = data.clientData.name
              }
            }, {root: true})
          }
        }

        return clientName
      },

      ttlResolver ('getCachedClientName')
    ),

    getCachedRegistrar: _memoize (
      /**
       * get the registrar by it's ID
       *
       * @param id                registrar ID
       * @param rootState         store root state
       * @param dispatch          function to be used for calling store actions
       * @return {Promise<*>}     promise, resolving into registrar
       *                          (may be {@code undefined})
       */
      async (id, {rootState, dispatch}) => {
        let reg

        if (id) {
          await dispatch ('request/fetchData', {
            op: 'registrar/load',
            params: {id},
            cb: data => {
              reg = data.registrarData
            }
          }, {root: true})
        }

        return reg
      }
    ),

    getCachedRegistryTypeData: _memoize (
      /**
       * get the registry type name by it's ID
       *
       * @param id                registry type ID
       * @param state             store module state
       * @param dispatch          function to be used for calling store actions
       * @return {Promise<*>}     promise, resolving into registry type name
       *                          (if no registry type name for given ID could
       *                          be found, then the given ID will be used as
       *                          the registry type name)
       */
      async (id, {state, dispatch}) => {
        const cache = state.getCachedRegistryTypeData.cache

        const registryTypeData =
          await getCachedRegistryTypeData (dispatch, cache)

        return registryTypeData[id] || {label: id}
      },

      ttlResolver ('getCachedRegistryTypeData')
    ),

    getCachedRegistrars: _memoize (
      /**
       * get the registrar list
       *
       * @param rootState         store root state
       * @param dispatch          function to be used for calling store actions
       * @return {Promise<*>}     promise, resolving into client name
       *                          (may be {@code undefined})
       */
      async ({rootState, dispatch}) => {
        let registrars
        await dispatch ('request/fetchData', {
          op: 'registrar/list',
          params: {},
          cb: data => {
            registrars = data.registrars
          }
        }, {root: true})

        return registrars
      },
      () => 'getCachedRegistrars'),

    getCachedDomainStates: _memoize (
      /**
       * get the domain states that are possible
       *
       * @param state             store module state
       * @param dispatch          function to be used for calling store actions
       * @return {Promise<*>}     promise, resolving into the possible states
       */
      async ({state, dispatch}) => {
        const cache = state.getCachedDomainStates.cache

        const states =
          await getCachedDomainStates (dispatch, cache)

        return states
      },
      ttlResolver ('getCachedDomainStates')
    ),

    getCachedNameServers: _memoize (
      /**
       * get the configured name server
       *
       * @param dispatch          function to be used for calling store actions
       * @return {Promise<*>}     promise, resolving into the possible states
       */
      async ({dispatch}) => {
        let ns
        let ts

        await dispatch ('request/fetchData', {
          op: 'zone/load-name-servers',
          cb: data => {
            ns = data.nameServers
            ts = data.transferServer
          }
        }, {root: true})

        return {ns, ts}
      },
      () => 'getCachedNameServers'
    ),

    getCachedVisibleRegistryMetaData: _memoize (
      /**
       * get the meta data for all visible registries
       *
       * @param dispatch          function to be used for calling store actions
       * @return {Promise<*>}     promise, resolving into the meta data
       */
      async ({dispatch}) => {
        let metaData

        await dispatch ('request/fetchData', {
          op: 'registry/loadData',
          cb: data => {
            metaData = data.registryData
          }
        }, {root: true})

        return metaData
      },
      () => 'getCachedVisibleRegistryMetaData'
    ),

    accountingActions: null
  },

  mutations: {
    setAccountingActions (state, actions) {
      state.accountingActions = actions
    }
  },

  actions: {
    /**
     * get the client name by it's ID
     *
     * @param context           action context
     * @param id                client ID
     * @return {Promise<*>}     promise, resolving into client name
     *                          (may be {@code undefined})
     */
    async getClientName (context, id) {
      return context.state.getCachedClientName (id, context)
    },

    /**
     * get the registrar by it's ID
     *
     * @param context           action context
     * @param id                registrar ID
     * @return {Promise<*>}     promise, resolving into registrar
     *                          (may be {@code undefined})
     */
    async getRegistrar (context, id) {
      return context.state.getCachedRegistrar (id, context)
    },

    /**
     * get the registrars
     *
     * @param context           action context
     * @return {Promise<*>}     promise, resolving into registrars
     *                          (may be {@code undefined})
     */
    async getRegistrars (context) {
      return context.state.getCachedRegistrars (context)
    },

    /**
     * clear the registrars cache
     *
     * @param context           action context
     */
    clearRegistrars (context) {
      context.state.getCachedRegistrars.cache = new _memoize.Cache ()
    },

    /**
     * reset the registry type data cache
     *
     * @param {context} the action context
     */
    clearRegistryTypeDataCache ({state}) {
      state.getCachedRegistryTypeData.cache = new Map ()
    },

    /**
     * get extended client name in format `Client Name (clientId)`
     *
     * @param dispatch          function to be used for calling store actions
     * @param id                client ID
     * @return {Promise<*>}     promise, resolving into extended client name
     *                          (may be {@code undefined})
     */
    async getClientNameExt ({dispatch}, id) {
      let clientNameExt

      if (id) {
        const clientName = await dispatch ('getClientName', id)
        clientNameExt = clientName
          ? formatClient ({name: clientName, id})
          : id
      }

      return clientNameExt
    },

    /**
     * get the registry type name by it's ID
     *
     * @param context           action context
     * @param id                registry type ID
     * @param cutTlds           if the number of tlds is lager than this number
     *                          the rest gets cut (if not given nothing gets cut)
     * @return {Promise<*>}     promise, resolving into registry type name
     *                          (if no registry type name for given ID could be
     *                          found, then the given ID will be used as
     *                          the registry type name)
     */
    async getRegistryTypeName (context, {id, cutTlds = 0}) {
      let registryTypeData

      if (id) {
        registryTypeData =
          await context.state.getCachedRegistryTypeData (id, context)
      }

      return registryTypeData ? formatLabel (registryTypeData, cutTlds) : id
    },

    /**
     * get data for all available registry types
     *
     * @param state             the module state
     * @param dispatch          function to be used for calling store actions
     * @return {Promise<*>}     promise, resolving into the object, which keys
     *                          are registry type IDs and the values are the
     *                          registry type data
     */
    async getRegistryTypeData ({state, dispatch}, includeInactive = false) {
      const cache = state.getCachedRegistryTypeData.cache

      const regData = await getCachedRegistryTypeData (dispatch, cache)

      if (includeInactive) {
        return regData
      }

      const filtered = {}

      Object.keys (regData).forEach ((regType) => {
        const regInfo = regData[regType]
        if (regInfo.inactive) {
          return
        }
        filtered[regType] = regInfo
      })

      return filtered
    },

    /**
     * get all available domain states
     *
     * @param state             the module state
     * @param dispatch          function to be used for calling store actions
     * @return {Promise<*>}     promise, resolving into the domain states
     */
    async getDomainStates (context) {
      return context.state.getCachedDomainStates (context)
    },

    /**
     * get the available name servers
     *
     * @param context           action context
     * @return {Promise<*>}     promise, resolving into the available name servers
     *                          (may be {@code undefined})
     */
    async getNameServers (context) {
      return context.state.getCachedNameServers (context).then ((d) => d.ns)
    },

    /**
     * get the available transfer server servers
     *
     * @param context           action context
     * @return {Promise<*>}     promise, resolving into the available transfer servers
     *                          (may be {@code undefined})
     */
    async getTransferServers (context) {
      return context.state.getCachedNameServers (context).then ((d) => d.ts)
    },

    /**
     * get the meta data for all visible registries
     *
     * @param context           action context
     * @return {Promise<*>}     promise, resolving into the meta data
     *                          (may be {@code undefined})
     */
    async getVisibleRegistryMetaData (context) {
      return context.state.getCachedVisibleRegistryMetaData (context)
    }
  }
}
