// this libraries are used to provide polyfills needed by IE-11
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'

import vuetify from '@/app/vuetify'

const cookiesEnabled = navigator.cookieEnabled

let props

if (cookiesEnabled) {
  const appProps = require ('./app')
  props = appProps.default
} else {
  const DisabledCookiesPage = () => import ('@/app/CookiesDisabledPage')
  props = {
    render: mount => mount (DisabledCookiesPage)
  }
}

new Vue ({
  name: 'Root',
  vuetify,
  ...props
}).$mount ('#app')
