import Vue from 'vue'
import {sync} from 'vuex-router-sync'
import Vuelidate from 'vuelidate'
import VueClipboard from 'vue-clipboard2'

/* App */
import App from '@/app/App'
import router from '@/app/routes'
import store from '@/app/store'
import i18n from '@/app/i18n'
import '@/app/core'

// activate user tracking with Matomo (Piwik)
if (process.env.VUE_APP_MATOMO_ENABLED) {
  import ('./app/matomo').then (({getMatomoInitArgs}) => {
    Vue.use (...getMatomoInitArgs (router))
  })
}

sync (store, router)

Vue.use (Vuelidate)

Vue.use (VueClipboard)

Vue.config.productionTip = false

export default {
  router,
  store,
  i18n,
  render: mount => mount (App)
}
