import BaseConstraint from './BaseConstraint'

/**
 * the constraint, which is satisfied if the user operation mode is the same
 * as the specified in constructor
 */
export default class OperationModeConstraint extends BaseConstraint {
  /**
   * create the constraint with specified operation mode
   *
   * @param operationMode     operation mode, which the user should have to
   *                          satisfy the constraint
   */
  constructor (operationMode) {
    super ({
      check () {
        return this.matchOperationMode ()
      }
    })

    this.operationMode = operationMode
  }

  /**
   * checks whether the user operation mode is the same as the operation mode,
   * specified in constraint
   *
   * @return {boolean}      {@code true} if the user operation mode is the same
   *                        as the operation mode, specified in constraint,
   *                        {@code false} otherwise
   */
  matchOperationMode () {
    return this.operationMode === this.user.operationMode
  }
}
