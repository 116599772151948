import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/app/store'

import createUser from '@/app/core/permissions/user'

import {
  AllConstraint,
  AnyConstraint,
  AuthenticatedConstraint,
  ClientExistenceConstraint,
  ClientTypeConstraint,
  NotAuthenticatedConstraint,
  OperationModeConstraint,
  PermissionsConstraint
} from '@/app/core/permissions/constraints'
import {MATCH_ANY_PERMISSION}
  from '@/app/core/permissions/constraints/PermissionsConstraint'

// Components
// Conditional component import is required for generation of separate
// JavaScript files for each route. Such files will be loaded on demand.
// const Faq = () => import ('./pages/Documentation/Faq')
const ApplicationViewDispatcher = () => import ('./pages/Domain/ApplicationViewDispatcher')
const AuditReports = () => import ('./pages/Account/AuditReports')
const BePasswordsView = () => import ('./pages/Services/BePasswords/BePasswordView.vue')
const BookEntryCreate = () => import ('./pages/Account/BookEntryCreate')
const BookEntrySearch = () => import ('./pages/Account/BookEntrySearch')
const BookEntryView = () => import ('./pages/Account/BookEntryView')
const ClaimsNotice = () => import ('./pages/ClaimsNotice/ClaimsNotice')
const Client = () => import ('./pages/Client/Client')
const ClientCreate = () => import ('./pages/Client/ClientCreate')
const ClientEdit = () => import ('./pages/Client/ClientEdit')
const ClientProfile = () => import ('./pages/Client/ClientProfile')
const ClientView = () => import ('./pages/Client/ClientView')
const ContactClone = () => import ('./pages/Contact/ContactClone')
const ContactCreate = () => import ('./pages/Contact/ContactCreate')
const ContactEdit = () => import ('./pages/Contact/ContactEdit')
const ContactInquire = () => import ('./pages/Contact/ContactInquire')
const ContactSearch = () => import ('./pages/Contact/ContactSearch')
const ContactView = () => import ('./pages/Contact/ContactView')
const CreateBookEntry = () => import ('./pages/Account/CreateBookEntry')
const CreditLimitList = () => import ('./pages/Account/CreditLimitList')
const Dashboard = () => import ('./pages/Dashboard/Dashboard')
const Documents = () => import ('./pages/Documentation/Documents.vue')
const DomainCheck = () => import ('./pages/Domain/DomainCheck')
const DomainClone = () => import ('./pages/Domain/DomainClone')
const DomainCreate = () => import ('./pages/Domain/DomainCreate')
const DomainEdit = () => import ('./pages/Domain/DomainEdit')
const DomainInquire = () => import ('./pages/Domain/DomainInquire')
const DomainSearch = () => import ('./pages/Domain/DomainSearch')
const DomainStatistics = () => import ('./pages/Statistics/DomainStatistics')
const DomainTransfer = () => import ('./pages/Domain/Transfer/DomainTransfer')
const DomainView = () => import ('./pages/Domain/DomainView')
const EmailSearch = () => import ('./pages/Email/EmailSearch')
const EmailVerification = () => import ('./pages/Services/EmailVerification')
const EmailView = () => import ('./pages/Email/EmailView')
const EmptyContainer = () => import ('./core/components/EmptyContainer/EmptyContainer')
const Error404 = () => import ('./pages/Error/404')
const Error500 = () => import ('./pages/Error/500')
const HostCreate = () => import ('./pages/Host/HostCreate')
const HostEdit = () => import ('./pages/Host/HostEdit')
const HostSearch = () => import ('./pages/Host/HostSearch')
const HostView = () => import ('./pages/Host/HostView')
const InvoiceSearch = () => import ('./pages/Account/InvoiceSearch')
const InvoiceView = () => import ('./pages/Account/InvoiceView')
const KeyTrustStores = () => import ('./pages/Configuration/KeyTrustStores')
const Layout = () => import ('./Layout')
const Login = () => import ('./pages/Login/Login')
const ManageMachineClients = () => import ('./pages/Client/ManageMachineClients')
const Order = () => import ('./pages/Order/Order')
const OverallResults = () => import ('./pages/Account/OverallResults')
const PasswordReset = () => import ('./pages/PasswordReset')
const PasswordResetDone = () => import ('./pages/PasswordReset/Done')
const PasswordResetSetNew = () => import ('./pages/PasswordReset/SetNew')
const PayloadDoc = () => import ('./pages/Documentation/Payload')
const Registrar = () => import ('./pages/Registrar/Registrar')
const RegistrarCreate = () => import ('./pages/Registrar/RegistrarCreate')
const RegistrarEdit = () => import ('./pages/Registrar/RegistrarEdit')
const RegistrarView = () => import ('./pages/Registrar/RegistrarView')
const RegistryConfiguration = () => import ('./pages/Configuration/Registries')
const RegistryCreate = () => import ('./pages/Configuration/RegistryCreate')
const RegistryView = () => import ('./pages/Dashboard/RegistryView')
const ReportList = () => import ('./pages/Report/ReportList')
const SendNotification = () => import ('./pages/Services/Notifications/SendNotifications')
const Tariff = () => import ('./pages/Account/Tariff')
const TariffView = () => import ('./pages/Account/TariffView')
const TempCreditLimitCreate = () => import ('./pages/Account/TempCreditLimitCreate')
const Template = () => import ('./pages/Services/Template')
const TemplateCreate = () => import ('./pages/Services/TemplateCreate')
const TemplateEdit = () => import ('./pages/Services/TemplateEdit')
const Totp = () => import ('./pages/Login/Totp')
const TotpInit = () => import ('./pages/Login/TotpInit')
const TransferAgreement = () => import ('./pages/Transfer/TransferAgreement')
const TransferConfirm = () => import ('./pages/Transfer/TransferConfirm')
const TransferStatus = () => import ('./pages/TransferStatus/TransferStatus')
const UploadBookEntries = () => import ('./pages/Account/UploadBookEntries')
const User = () => import ('./pages/User/User')
const UserCreate = () => import ('./pages/User/UserCreate')
const UserEdit = () => import ('./pages/User/UserEdit')
const UserProfile = () => import ('./pages/User/Profile')
const UserView = () => import ('./pages/User/UserView')
const WapVerify = () => import ('./pages/Wap/Verify')
const WDRPReportDownload = () => import ('./pages/Services/WDRPReportDownload')
const WDRPSearch = () => import ('./pages/Services/WDRPSearch')
const ZoneCreate = () => import ('./pages/Zone/ZoneCreate')
const ZoneEdit = () => import ('./pages/Zone/ZoneEdit')
const ZoneSearch = () => import ('./pages/Zone/ZoneSearch')
const ZoneView = () => import ('./pages/Zone/ZoneView')

Vue.use (VueRouter)

/**
 * constraint, used for view-able registry objects
 * @type {PermissionsConstraint}
 */
const ObjectsViewConstraint = new PermissionsConstraint (
  ['ViewAllObjects', 'ViewOwnObjects', 'ViewSubClientObjects'],
  MATCH_ANY_PERMISSION
)

/**
 * constraint, used for view all or view own registry object
 * @type {PermissionsConstraint}
 */
const ObjectsViewAllOwnConstraint = new PermissionsConstraint (
  ['ViewAllObjects', 'ViewOwnObjects'],
  MATCH_ANY_PERMISSION
)

/**
 * constraint, used for manageable registry objects
 * @type {PermissionsConstraint}
 */
const ObjectsManageConstraint = new PermissionsConstraint (
  ['ManageAllObjects', 'ManageOwnObjects', 'ManageSubClientObjects'],
  MATCH_ANY_PERMISSION
)

/**
 * constraint for users that do not belong to a reseller
 * @type {ClientTypeConstraint}
 */
const NoResellerConstraint = new ClientTypeConstraint (
  ['Member', 'Partner', 'Internal', 'Agent'],
  true)

/**
 * constraint, satisfied if some user can be managed by the user
 * @type {BaseConstraint}
 */
const UsersManageConstraint = new AnyConstraint ([
  new PermissionsConstraint (['ManageAllEntities']),
  new AllConstraint ([
    new ClientExistenceConstraint (),
    new PermissionsConstraint (
      ['ManageSubEntities', 'ManageOwnUsers'],
      MATCH_ANY_PERMISSION
    )
  ])
])

/**
 * constraint, used for user operations
 * @type {PermissionsConstraint}
 */
const RegistrarsManageConstraint = new PermissionsConstraint (
  ['ManageAllEntities']
)

/**
 * constraint, satisfied if own client can be managed by the user
 * @type {BaseConstraint}
 */
const OwnClientManageConstraint = new AllConstraint ([
  new ClientExistenceConstraint (),
  new PermissionsConstraint (
    ['ManageAllEntities', 'ManageOwnClient'],
    MATCH_ANY_PERMISSION
  )
])

/**
 * constraint, satisfied if some client can be managed by the user
 * @type {BaseConstraint}
 */
const ClientManageConstraint = new AnyConstraint ([
  new PermissionsConstraint (['ManageAllEntities']),
  new AllConstraint ([
    new ClientExistenceConstraint (),
    new PermissionsConstraint (
      ['ManageSubEntities', 'ManageOwnClient'],
      MATCH_ANY_PERMISSION
    )
  ])
])

/**
 * constraint, satisfied if more then one client can be managed by the user
 * @type {BaseConstraint}
 */
const MultipleClientsManageConstraint = new AnyConstraint ([
  new PermissionsConstraint (['ManageAllEntities']),
  new AllConstraint ([
    new ClientExistenceConstraint (),
    new PermissionsConstraint (['ManageSubEntities'])
  ])
])

/**
 * constraint, used for service operations
 * @type {PermissionsConstraint}
 */
const ServicesManageConstraint = new PermissionsConstraint (
  ['ManageServices']
)

/**
 * constraint, used to manage registry configurations
 * @type {PermissionsConstraint}
 */
const RegistriesManageConstraint = new PermissionsConstraint (
  ['ManageRegistryConfiguration']
)

/**
 * constraint, used to perform accounting tasks
 * @type {PermissionsConstraint}
 */
const DoAccountingConstraint = new PermissionsConstraint (['DoAccounting'])

const CreateOrderConstraint = new PermissionsConstraint (['CreateOrder'])

/**
 * assign the search page component properties from route query
 */
const searchPropsFromQuery = route => {
  function normalizePagerState (obj) {
    const pagerState = {}

    for (const prop in obj) {
      switch (prop) {
        case 'rowsPerPage':
        case 'page': {
          const val = Number.parseInt (obj[prop])
          if (!Number.isNaN (val)) {
            pagerState[prop] = val
          }
          break
        }

        case 'sortBy':
          if (obj[prop]) {
            pagerState[prop] = obj[prop]
          }
          break

        case 'descending':
          pagerState[prop] = (obj[prop] === true || obj[prop] === 'true')
          break
      }
    }

    return pagerState
  }

  let props = {}

  const filterStr = route.query.filter

  if (route.query && filterStr) {
    const filter = JSON.parse (filterStr)

    if (filter) {
      const pagerState = normalizePagerState (route.query)

      props = {filter, ...pagerState}
    }
  }

  const type = route.name.substring (0, route.name.indexOf ('.'))

  return {
    ...props,
    type
  }
}

const router = new VueRouter ({
  // can be out-commented to disable
  // modification of window.location.pathname
  // mode: 'history',

  routes: [
    {
      path: '/claimsnotice/:id',
      component: ClaimsNotice,
      props: true
    },
    {
      path: '/wap/verify',
      component: WapVerify,
      props: (route) => ({
        address: route.query.e,
        id: Number.parseInt (route.query.i),
        secret: route.query.s
      })
    },
    {
      path: '/transfer/confirmation',
      component: TransferConfirm,
      props: (route) => ({
        lang: route.query.l,
        role: Number.parseInt (route.query.r),
        transferId: Number.parseInt (route.query.i),
        secret: route.query.s
      })
    },
    {
      path: '/transfer/agreement',
      component: TransferAgreement,
      props: (route) => ({
        lang: route.query.l,
        clientId: Number.parseInt (route.query.mid)
      })
    },
    {
      path: '',
      component: Layout,
      children: [
        {
          path: '/',
          name: 'dashboard',
          meta: {
            icon: 'dashboard',
            label: 'dashboard.title',
            constraint: AuthenticatedConstraint
          },
          component: Dashboard
        },
        {
          path: '/login',
          name: 'login',
          meta: {
            // icon: 'new',
            hideInMenu: true,
            hideSidebar: true,
            label: 'login.title',
            constraint: NotAuthenticatedConstraint,
            denied: 'dashboard'
          },
          component: Login
        },
        {
          path: '/totp',
          name: 'totp',
          meta: {
            // icon: 'new',
            hideInMenu: true,
            hideSidebar: true,
            label: 'totp.title',
            constraint: new OperationModeConstraint ('TWO_FACTOR_AUTH'),
            transient: true
          },
          component: Totp
        },
        {
          path: '/totp-init',
          name: 'totp.init',
          meta: {
            // icon: 'new',
            hideInMenu: true,
            hideSidebar: true,
            label: 'totpInit.title',
            constraint: new OperationModeConstraint ('TWO_FACTOR_AUTH_INIT'),
            transient: true
          },
          component: TotpInit
        },
        {
          path: '/password-reset',
          name: 'passwordReset',
          meta: {
            hideInMenu: true,
            hideSidebar: true,
            label: 'passwordReset.title',
            transient: true
          },
          component: PasswordReset
        },
        {
          path: '/password-reset/done',
          name: 'passwordReset.done',
          meta: {
            hideInMenu: true,
            hideSidebar: true,
            label: 'passwordReset.done.title',
            transient: true
          },
          component: PasswordResetDone
        },
        {
          path: '/password-reset/set-new',
          name: 'passwordReset.setNew',
          meta: {
            hideInMenu: true,
            hideSidebar: true,
            label: 'passwordReset.setNew.title',
            transient: true
          },
          component: PasswordResetSetNew
        },
        {
          path: '/registry/:id',
          name: 'registry.view',
          meta: {
            hideInMenu: true,
            label: 'registry.view.title',
            constraint: ObjectsViewConstraint
          },
          props: true,
          component: RegistryView
        },
        {
          path: '/domain',
          name: 'domain',
          meta: {
            icon: 'dns',
            label: 'domain.title',
            constraint: ObjectsViewConstraint
          },
          component: EmptyContainer,
          children: [
            {
              path: '/domain/search',
              name: 'domain.search',
              meta: {
                icon: 'search',
                label: 'domain.search.title',
                constraint: ObjectsViewConstraint
              },
              component: DomainSearch,
              props: searchPropsFromQuery
            },
            {
              path: '/domain/application/search',
              name: 'application.search',
              meta: {
                icon: 'search',
                label: 'application.search.title',
                constraint: ObjectsViewConstraint
              },
              component: DomainSearch,
              props: searchPropsFromQuery
            },
            {
              path: '/domain/view/:name',
              name: 'domain.view',
              meta: {
                hideInMenu: true,
                label: 'domain.view.title',
                constraint: ObjectsViewConstraint
              },
              props: true,
              component: DomainView
            },
            {
              path: '/domain/view/version/:vid',
              name: 'domain.view.version',
              meta: {
                hideInMenu: true,
                label: 'domain.view.title',
                constraint: ObjectsViewConstraint
              },
              props: true,
              component: DomainView
            },
            {
              path: '/domain/view/id/:id',
              name: 'domain.view.id',
              meta: {
                hideInMenu: true,
                label: 'domain.view.title',
                constraint: ObjectsViewConstraint
              },
              props: true,
              component: DomainView
            },
            {
              path: '/application/view/:name',
              name: 'application.view',
              meta: {
                hideInMenu: true,
                label: 'application.view.title',
                constraint: ObjectsViewConstraint
              },
              props: true,
              component: ApplicationViewDispatcher
            },
            {
              path: '/application/view/version/:vid',
              name: 'application.view.version',
              meta: {
                hideInMenu: true,
                label: 'application.view.title',
                constraint: ObjectsViewConstraint
              },
              props: route => ({
                ...route.params,
                type: 'application'
              }),
              component: DomainView
            },
            {
              path: '/application/view/id/:id',
              name: 'application.view.id',
              meta: {
                hideInMenu: true,
                label: 'application.view.title',
                constraint: ObjectsViewConstraint
              },
              props: route => ({
                ...route.params,
                type: 'application'
              }),
              component: DomainView
            },
            {
              path: '/domain/check',
              name: 'domain.check',
              meta: {
                icon: 'check',
                label: 'domain.check.title'
              },
              component: DomainCheck
            },
            {
              path: '/domain/inquire',
              name: 'domain.inquire',
              meta: {
                icon: 'send',
                label: 'domain.inquire.title'
              },
              component: DomainInquire
            },
            {
              path: '/domain/requesttransfer',
              name: 'domain.transfer',
              meta: {
                icon: 'call_received',
                label: 'domain.transfer.title'
              },
              component: DomainTransfer
            },
            {
              path: '/domain/transferstatus',
              name: 'transferstatus.check',
              meta: {
                icon: 'import_export',
                label: 'transferstatus.title'
              },
              component: TransferStatus
            },
            {
              path: '/domain/create',
              name: 'domain.create',
              meta: {
                icon: 'add_circle',
                label: 'domain.create.title',
                constraint: ObjectsManageConstraint
              },
              component: DomainCreate
            },
            {
              path: '/domain/edit/:name',
              name: 'domain.edit',
              meta: {
                hideInMenu: true,
                label: 'domain.edit.title',
                constraint: ObjectsManageConstraint
              },
              props: true,
              component: DomainEdit
            },
            {
              path: '/application/edit/id/:id',
              name: 'application.edit.id',
              meta: {
                hideInMenu: true,
                label: 'application.edit.title',
                constraint: ObjectsManageConstraint
              },
              props: route => ({
                ...route.params,
                type: 'application'
              }),
              component: DomainEdit
            },
            {
              path: '/domain/clone/:name',
              name: 'domain.clone',
              meta: {
                hideInMenu: true,
                label: 'domain.clone.title',
                constraint: ObjectsManageConstraint
              },
              props: true,
              component: DomainClone
            },
            {
              path: '/application/clone/id/:id',
              name: 'application.clone.id',
              meta: {
                hideInMenu: true,
                label: 'application.clone.title',
                constraint: ObjectsManageConstraint
              },
              props: route => ({
                ...route.params,
                type: 'application'
              }),
              component: DomainClone
            }
          ]
        },
        {
          path: '/contact',
          name: 'contact',
          meta: {
            icon: 'contacts',
            label: 'contact.title',
            constraint: ObjectsViewConstraint
          },
          component: EmptyContainer,
          children: [
            {
              path: '/contact/search',
              name: 'contact.search',
              meta: {
                icon: 'search',
                label: 'contact.search.title',
                constraint: ObjectsViewConstraint
              },
              component: ContactSearch,
              props: searchPropsFromQuery
            },
            {
              path: '/contact/view/version/:vid',
              name: 'contact.view.version',
              meta: {
                hideInMenu: true,
                label: 'contact.view.title',
                constraint: ObjectsViewConstraint
              },
              props: true,
              component: ContactView
            },
            {
              path: '/contact/view/id/:id',
              name: 'contact.view.id',
              meta: {
                hideInMenu: true,
                label: 'contact.view.title',
                constraint: ObjectsViewConstraint
              },
              props: true,
              component: ContactView
            },
            {
              path: '/contact/view/:registry/:handle',
              name: 'contact.view',
              meta: {
                hideInMenu: true,
                label: 'contact.view.title',
                constraint: ObjectsViewConstraint
              },
              props: true,
              component: ContactView
            },
            {
              path: '/contact/inquire',
              name: 'contact.inquire',
              meta: {
                icon: 'send',
                label: 'contact.inquire.title'
              },
              component: ContactInquire
            },
            {
              path: '/contact/create',
              name: 'contact.create',
              meta: {
                icon: 'add_circle',
                label: 'contact.create.title',
                constraint: ObjectsManageConstraint
              },
              component: ContactCreate
            },
            {
              path: '/contact/edit/:registry/:handle',
              name: 'contact.edit',
              meta: {
                hideInMenu: true,
                label: 'contact.edit.title',
                constraint: ObjectsManageConstraint
              },
              props: true,
              component: ContactEdit
            },
            {
              path: '/contact/clone/:registry/:handle',
              name: 'contact.clone',
              meta: {
                hideInMenu: true,
                label: 'contact.clone.title',
                constraint: ObjectsManageConstraint
              },
              props: true,
              component: ContactClone
            }
          ]
        },
        {
          path: '/host',
          name: 'host',
          meta: {
            icon: 'computer',
            label: 'host.title',
            constraint: ObjectsViewConstraint
          },
          component: EmptyContainer,
          children: [
            {
              path: '/host/search',
              name: 'host.search',
              meta: {
                icon: 'search',
                label: 'host.search.title',
                constraint: ObjectsViewConstraint
              },
              component: HostSearch,
              props: searchPropsFromQuery
            },
            {
              path: '/host/create',
              name: 'host.create',
              meta: {
                icon: 'add_circle',
                label: 'host.create.title',
                constraint: ObjectsManageConstraint
              },
              component: HostCreate
            },
            {
              path: '/host/view/version/:vid',
              name: 'host.view.version',
              meta: {
                hideInMenu: true,
                label: 'host.view.title',
                constraint: ObjectsViewConstraint
              },
              props: true,
              component: HostView
            },
            {
              path: '/host/view/id/:id',
              name: 'host.view.id',
              meta: {
                hideInMenu: true,
                label: 'host.view.title',
                constraint: ObjectsViewConstraint
              },
              props: true,
              component: HostView
            },
            {
              path: '/host/view/:registry/:handle',
              name: 'host.view',
              meta: {
                hideInMenu: true,
                label: 'host.view.title',
                constraint: ObjectsViewConstraint
              },
              props: true,
              component: HostView
            },
            {
              path: '/host/edit/:registry/:handle',
              name: 'host.edit',
              meta: {
                hideInMenu: true,
                label: 'host.edit.title',
                constraint: ObjectsManageConstraint
              },
              props: true,
              component: HostEdit
            }
          ]
        },
        {
          path: '/zone',
          name: 'zone',
          meta: {
            icon: 'settings_ethernet',
            label: 'zone.title',
            constraint: ObjectsViewConstraint
          },
          component: EmptyContainer,
          children: [
            {
              path: '/zone/search',
              name: 'zone.search',
              meta: {
                icon: 'search',
                label: 'zone.search.title',
                constraint: ObjectsViewConstraint
              },
              component: ZoneSearch,
              props: searchPropsFromQuery
            },
            {
              path: '/zone/create',
              name: 'zoe.create',
              meta: {
                icon: 'add_circle',
                label: 'zone.create.title',
                constraint: ObjectsManageConstraint
              },
              component: ZoneCreate
            },
            {
              path: '/zone/view/version/:vid',
              name: 'zone.view.version',
              meta: {
                hideInMenu: true,
                label: 'zone.view.title',
                constraint: ObjectsViewConstraint
              },
              props: true,
              component: ZoneView
            },
            {
              path: '/zone/view/id/:id',
              name: 'zone.view.id',
              meta: {
                hideInMenu: true,
                label: 'zone.view.title',
                constraint: ObjectsViewConstraint
              },
              props: true,
              component: ZoneView
            },
            {
              path: '/zone/view/:name',
              name: 'zone.view',
              meta: {
                hideInMenu: true,
                label: 'zone.view.title',
                constraint: ObjectsViewConstraint
              },
              props: true,
              component: ZoneView
            },
            {
              path: '/zone/edit/:zoneId',
              name: 'zone.edit',
              meta: {
                hideInMenu: true,
                label: 'zone.edit.title',
                constraint: ObjectsManageConstraint
              },
              props: true,
              component: ZoneEdit
            }
          ]
        },
        {
          path: '/email/search',
          name: 'service.emailsearch',
          meta: {
            icon: 'email',
            label: 'emailsearch.title',
            constraint: ObjectsViewConstraint
          },
          component: EmailSearch,
          props: searchPropsFromQuery
        },
        {
          path: '/report/list',
          alias: '/reports',
          name: 'report.list',
          meta: {
            icon: 'list',
            label: 'report.list.title',
            constraint: ObjectsViewAllOwnConstraint
          },
          component: ReportList
        },
        {
          path: '/statistics/domain',
          alias: '/statistics',
          name: 'statistics.domain',
          meta: {
            icon: 'timeline',
            label: 'statistics.domain.title',
            constraint: ObjectsViewAllOwnConstraint
          },
          component: DomainStatistics
        },
        {
          path: '/email/view/:id',
          name: 'service.emailview',
          meta: {
            hideInMenu: true,
            label: 'emailview.title',
            constraint: AuthenticatedConstraint
          },
          component: EmailView,
          props (route) {
            const props = {...route.params}
            props.id = Number.parseInt (props.id)
            return props
          }
        },
        {
          path: '/registrar',
          name: 'registrar',
          meta: {
            icon: 'person',
            label: 'registrar.title.main',
            constraint: AuthenticatedConstraint
          },
          component: EmptyContainer,
          children: [
            {
              path: '/registrar/list',
              name: 'registrar.list',
              meta: {
                icon: 'list',
                label: 'registrar.title.list',
                constraint: RegistrarsManageConstraint
              },
              component: Registrar
            },
            {
              path: '/registrar/create',
              name: 'registrar.create',
              meta: {
                icon: 'add_circle',
                label: 'registrar.title.create',
                constraint: RegistrarsManageConstraint
              },
              component: RegistrarCreate,
              props: true
            },
            {
              path: '/registrar/view/:id',
              name: 'registrar.view',
              meta: {
                hideInMenu: true,
                label: 'registrar.title.view',
                constraint: RegistrarsManageConstraint
              },
              props: true,
              component: RegistrarView
            },
            {
              path: '/registrar/edit/:id',
              name: 'registrar.edit',
              meta: {
                hideInMenu: true,
                label: 'registrar.title.update',
                constraint: RegistrarsManageConstraint
              },
              props: true,
              component: RegistrarEdit
            }
          ]
        },
        {
          path: '/client',
          name: 'client',
          meta: {
            icon: 'account_box',
            label: 'client.title'
          },
          component: EmptyContainer,
          children: [
            {
              path: '/client/list',
              name: 'client.list',
              meta: {
                icon: 'list',
                label: 'client.list.title',
                constraint: MultipleClientsManageConstraint
              },
              component: Client
            },
            {
              path: '/client/create',
              name: 'client.create',
              meta: {
                icon: 'add_circle',
                label: 'client.cu.title.create',
                constraint: MultipleClientsManageConstraint
              },
              component: ClientCreate
            },
            {
              path: '/client/view/:id',
              name: 'client.view',
              meta: {
                hideInMenu: true,
                constraint: ClientManageConstraint
              },
              props: true,
              component: ClientView
            },
            {
              path: '/client/edit/:id',
              name: 'client.edit',
              meta: {
                hideInMenu: true,
                constraint: MultipleClientsManageConstraint
              },
              props: true,
              component: ClientEdit
            },
            {
              path: '/client/clone/:id',
              name: 'client.clone',
              meta: {
                hideInMenu: true,
                constraint: MultipleClientsManageConstraint
              },
              component: ClientEdit,
              props: true
            },
            {
              path: '/client/profile',
              name: 'client.profile',
              meta: {
                icon: 'mode_edit',
                label: 'client.profile.title',
                // the user should belong to a client (not client-less admin)
                // and has admin permissions
                constraint: OwnClientManageConstraint
              },
              component: ClientProfile
            },
            {
              path: '/client/notification',
              name: 'client.notification',
              meta: {
                icon: 'add_alert',
                label: 'client.notification.title',
                constraint: new PermissionsConstraint (
                  ['ManageAllEntities']
                )
              },
              component: SendNotification
            },
            {
              path: '/client/machineclients',
              name: 'client.machineclients',
              meta: {
                icon: 'settings_input_component',
                label: 'client.machineclients.title',
                constraint: ClientManageConstraint
              },
              component: ManageMachineClients
            }
          ]
        },
        {
          path: '/user',
          name: 'user',
          meta: {
            icon: 'person_outline',
            label: 'user.title',
            constraint: AuthenticatedConstraint
          },
          component: EmptyContainer,
          children: [
            {
              path: '/user/list',
              name: 'user.list',
              meta: {
                icon: 'list',
                label: 'user.list.title',
                constraint: UsersManageConstraint
              },
              component: User
            },
            {
              path: '/user/create',
              name: 'user.create',
              meta: {
                icon: 'add_circle',
                label: 'user.cu.title.create',
                constraint: UsersManageConstraint
              },
              component: UserCreate,
              props: true
            },
            {
              path: '/user/view/:id',
              name: 'user.view',
              meta: {
                hideInMenu: true,
                constraint: UsersManageConstraint
              },
              props: true,
              component: UserView
            },
            {
              path: '/user/edit/:id',
              name: 'user.edit',
              meta: {
                hideInMenu: true,
                constraint: UsersManageConstraint
              },
              props: true,
              component: UserEdit
            },
            {
              path: '/user/profile',
              name: 'user.profile',
              meta: {
                icon: 'mode_edit',
                label: 'user.profile.title'
              },
              component: UserProfile
            }
          ]
        },
        {
          path: '/order',
          name: 'order',
          meta: {
            icon: 'send',
            label: 'order.title',
            constraint: CreateOrderConstraint
          },
          component: Order
        },
        {
          path: '/accounting',
          name: 'accounting',
          meta: {
            icon: 'monetization_on',
            label: 'accounting.title',
            constraint: new AllConstraint (
              [DoAccountingConstraint, NoResellerConstraint])
          },
          component: EmptyContainer,
          children: [
            {
              path: '/accounting/tarif-edit',
              name: 'tariff.edit',
              meta: {
                icon: 'edit',
                label: 'tariff.edit.title',
                constraint: new PermissionsConstraint (['UploadTariffs'])
              },
              component: Tariff
            },
            {
              path: '/accounting/tarif-view',
              name: 'tariff.view',
              meta: {
                label: 'tariff.view.title',
                icon: 'visibility'
              },
              component: TariffView
            },
            {
              path: '/accounting/bookentry-search',
              name: 'bookentry.search',
              meta: {
                label: 'bookentry.search.title',
                icon: 'search'
              },
              component: BookEntrySearch,
              props: searchPropsFromQuery
            },
            {
              path: '/accounting/bookentry/:id',
              name: 'bookentry.view',
              meta: {
                label: 'bookentry.view.title',
                hideInMenu: true
              },
              component: BookEntryView,
              props (route) {
                const props = {...route.params}
                props.id = Number.parseInt (props.id)
                return props
              }
            },
            {
              path: '/accounting/deposit-create',
              name: 'bookentry.create',
              meta: {
                label: 'bookentry.create.title',
                icon: 'add_circle',
                constraint: new PermissionsConstraint (['UploadBookEntries'])
              },
              component: CreateBookEntry
            },
            {
              path: '/accounting/create-book-entry',
              name: 'bookentry.createAdvanced',
              meta: {
                label: 'bookentry.createAdvanced.title',
                icon: 'confirmation_number',
                constraint: new PermissionsConstraint (['UploadBookEntries'])
              },
              component: BookEntryCreate
            },
            {
              path: '/accounting/bookentry-upload',
              name: 'bookentry.upload',
              meta: {
                label: 'bookentry.upload.title',
                icon: 'cloud_upload',
                constraint: new PermissionsConstraint (['UploadBookEntries'])
              },
              component: UploadBookEntries
            },
            {
              path: '/accounting/create-credit-limit',
              name: 'creditlimit.create',
              meta: {
                label: 'creditlimit.create.title',
                icon: 'remove_circle',
                constraint: new PermissionsConstraint (['ManageAllEntities'])
              },
              component: TempCreditLimitCreate
            },
            {
              path: '/accounting/list-credit-limits',
              name: 'creditlimit.list',
              meta: {
                label: 'creditlimit.list.title',
                icon: 'list'
              },
              component: CreditLimitList
            },
            {
              path: '/accounting/invoices',
              name: 'accounting.invoices',
              meta: {
                label: 'invoices.title',
                icon: 'receipt'
              },
              component: InvoiceSearch,
              props: searchPropsFromQuery
            },
            {
              path: '/accounting/audit-reports',
              name: 'auditreports.view',
              meta: {
                label: 'auditreports.view.title',
                icon: 'trending_up',
                constraint: new PermissionsConstraint (['ManageAllEntities'])
              },
              component: AuditReports
            },
            {
              path: '/accounting/overall-results',
              name: 'overall.results.view',
              meta: {
                label: 'overall.results.view.title',
                icon: 'account_balance',
                constraint: new PermissionsConstraint (['ManageAllEntities'])
              },
              component: OverallResults,
              props: searchPropsFromQuery
            },
            {
              path: '/accounting/invoice/:id',
              name: 'accounting.invoice.view',
              meta: {
                label: 'invoices.view.title',
                hideInMenu: true
              },
              props: true,
              component: InvoiceView
            }
          ]
        },
        {
          path: '/config',
          name: 'config',
          meta: {
            icon: 'settings',
            label: 'config.title',
            constraint: RegistriesManageConstraint
          },
          component: EmptyContainer,
          children: [
            {
              path: '/config/registries/list',
              name: 'config.registries',
              meta: {
                icon: 'build',
                label: 'config.registries.title',
                constraint: RegistriesManageConstraint
              },
              component: RegistryConfiguration
            },
            {
              path: '/config/registries/create',
              name: 'config.registries.create',
              meta: {
                icon: 'add_circle',
                label: 'config.registries.create.title',
                constraint: RegistriesManageConstraint
              },
              component: RegistryCreate
            },
            {
              path: '/config/certstores',
              name: 'config.certstores',
              meta: {
                icon: 'vpn_key',
                label: 'config.certstores.title',
                constraint: RegistriesManageConstraint
              },
              component: KeyTrustStores
            }
          ]
        },
        {
          path: '/service',
          name: 'service',
          meta: {
            icon: 'flag',
            label: 'service.title',
            constraint: ServicesManageConstraint
          },
          component: EmptyContainer,
          children: [
            {
              path: '/service/emailverification',
              name: 'service.emailverification',
              meta: {
                icon: 'mail',
                label: 'service.emailverification.title',
                constraint: AuthenticatedConstraint
              },
              component: EmailVerification
            },
            {
              path: '/service/wdrpdownload',
              name: 'service.wdrpdownload',
              meta: {
                icon: 'cloud_download',
                label: 'service.wdrpdownload.title',
                constraint: AuthenticatedConstraint
              },
              component: WDRPReportDownload
            },
            {
              path: '/service/wdrpsearch',
              name: 'service.wdrpsearch',
              meta: {
                icon: 'view_agenda',
                label: 'service.wdrpsearch.title',
                constraint: new PermissionsConstraint (
                  ['ManageAllEntities']
                )
              },
              component: WDRPSearch
            },
            {
              path: '/service/template-list',
              name: 'template.list',
              meta: {
                icon: 'list',
                label: 'template.list.title',
                constraint: new AllConstraint (
                  [ServicesManageConstraint, ObjectsViewConstraint])
              },
              component: Template
            },
            {
              path: '/service/template-create',
              name: 'template.create',
              meta: {
                icon: 'add_circle',
                label: 'template.cu.title.create',
                constraint: new AllConstraint (
                  [ServicesManageConstraint, ObjectsManageConstraint])
              },
              component: TemplateCreate
            },
            {
              path: '/service/template-edit/:id',
              name: 'template.edit',
              meta: {
                hideInMenu: true,
                constraint: new AllConstraint (
                  [ServicesManageConstraint, ObjectsManageConstraint])
              },
              props: true,
              component: TemplateEdit
            },
            {
              path: '/service/be-passwords',
              name: 'service.bePasswords',
              meta: {
                icon: 'lock_open',
                label: 'service.bePasswords.title',
                constraint: new PermissionsConstraint (
                  ['ManageAllEntities']
                )
              },
              component: BePasswordsView
            }
          ]
        },
        {
          path: '/doc',
          name: 'doc',
          meta: {
            icon: 'book',
            label: 'doc.title',
            constraint: AuthenticatedConstraint
          },
          component: EmptyContainer,
          children: [
            {
              path: '/doc/payload',
              name: 'payload-doc',
              meta: {
                label: 'doc.payload.title',
                constraint: AuthenticatedConstraint
              },
              props: {path: 'singlepage.html'},
              component: PayloadDoc
            },
            {
              path: '/doc/payload-multi',
              name: 'payload-multi-doc',
              meta: {
                label: 'doc.payload.multi.title',
                constraint: AuthenticatedConstraint
              },
              props: {path: 'mp/index.html'},
              component: PayloadDoc
            },
            {
              path: '/doc/rc',
              name: 'rc-doc',
              meta: {
                label: 'doc.rc.title',
                constraint: RegistriesManageConstraint
              },
              props: {path: 'rc/schema.html'},
              component: PayloadDoc
              // },
              // {
              //   path: '/docs/faq',
              //   name: 'faq',
              //   meta: {
              //     icon: 'question_answer',
              //     label: 'faq.title',
              //     constraint: AuthenticatedConstraint
              //   },
              //   component: Faq
            },
            {
              path: '/doc/documents',
              name: 'documents',
              meta: {
                label: 'doc.other.title',
                constraint: AuthenticatedConstraint
              },
              component: Documents
            }
          ]
        },
        {
          path: '/500',
          name: 'error500',
          component: Error500,
          meta: {
            hideInMenu: true,
            constraint: AuthenticatedConstraint,
            transient: true
          }
        },
        {
          path: '*',
          name: 'error404',
          component: Error404
        }
      ]
    }
  ]
})

router.beforeEach ((to, from, next) => {
  const user = createUser (store)

  const routeDenied = to.matched.some (routeRecord => {
    let isRouteDenied = false
    const constraint = routeRecord.meta.constraint

    if (constraint) {
      isRouteDenied = !constraint.isSatisfied (user)
    }

    return isRouteDenied
  })

  const titleKey = (to.meta?.label) || 'defaultPageTitle'

  if (routeDenied) {
    next ({
      name: (to.meta?.denied) || (
        user.isAuthenticated ? 'error500' : 'login'
      )
    })

    // save desired route if it's not explicitly market as `transient`
    if (!(to.meta?.transient)) {
      store.commit ('router/setDesiredRoute', {
        name: to.name,
        query: to.query,
        params: to.params,
        titleKey
      })
    }
  } else {
    store.commit ('router/setRouteTitleKey', titleKey)
    next ()
  }
})

export default router
