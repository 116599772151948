/**
 * get default values for registry config creation
 * @returns {Object}    default values for registry config creation
 */
export function getDefaultCreateParams () {
  return {
    config: '',
    comment: '',
    initialUse: 4
  }
}
