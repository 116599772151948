import BaseConstraint from './BaseConstraint'

// this constant can be used as the second constructor argument to specify,
// that ANY of given permission must match
export const MATCH_ANY_PERMISSION = false

/**
 * constraint, which is satisfied if user permissions matching permissions,
 * specified in constructor
 */
export default class PermissionsConstraint extends BaseConstraint {
  /**
   * create the constraint for given permissions
   *
   * @param permissions                   permissions to match
   * @param matchAllPermissions           flag, whether ALL (default)
   *                                      permissions must match or just ANY
   */
  constructor (permissions, matchAllPermissions = true) {
    super ({
      check () {
        return this.isAuthenticated () && this.matchPermissions ()
      }
    })

    this.permissions = permissions
    this.matchAllPermissions = matchAllPermissions
  }

  /**
   * checks whether user permissions matching permissions, specified in
   * constraint
   *
   * @return {boolean}    {@code true} if user permissions matching constraint
   *                      permissions, {@code false} otherwise
   */
  matchPermissions () {
    let match = this.matchAllPermissions

    for (const perm of this.permissions) {
      const hasCurrent = this.user.permissions.includes (perm)

      if (this.matchAllPermissions) {
        if (!hasCurrent) {
          match = false
          break
        }
      } else {
        if (hasCurrent) {
          match = true
          break
        }
      }
    }

    return match
  }
}
