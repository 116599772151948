import JWT from 'jwt-client'
import vueAuthInstance from '@/app/services/auth'
import jsonService from '@/app/services/json'

// amount of seconds until logout, which used to set the flag 'shouldRefresh'
// (show login refresh confirmation dialog or auto refresh login in 120 sec
// before auth expired)
const ttlSecondsForUserReaction = 120
// minimal amount of seconds since last API access to be taken in account
// to allow login auto refresh
// (if the backend API was used during last 5 min, then auto refresh login
// is allowed)
const apiAccessTolerance = 5 * 60

const getEpochSeconds = () => Math.round (new Date ().getTime () / 1000)
const getJwtTokenString = () => vueAuthInstance.getToken ()
const getJwtToken = () => JWT.read (getJwtTokenString ())
const getJwtClaims = () => getJwtToken ().claim
const getJwtIss = () => getJwtClaims ().iss
const getJwtSub = () => getJwtClaims ().sub
const getJwtExp = () => getJwtClaims ().exp
const getJwtNbf = () => getJwtClaims ().nbf
const isActive = () => vueAuthInstance.isAuthenticated ()
const isAuthenticated = () => (isActive () && !getJwtOpMode ())
const getJwtOpMode = () => (isActive () && getJwtClaims ().opMode)

const SESSION_START_TIME_KEY = 'sessionStartTime'
export const TOTP_SECRET_KEY = 'totp_id'

function getSessionStartTime () {
  return Number.parseInt (window.localStorage.getItem (SESSION_START_TIME_KEY))
}

function setSessionStartTime (sessionStartTime) {
  window.localStorage.setItem (SESSION_START_TIME_KEY, sessionStartTime)
}

const getTtl = () => (isActive ()
  ? (getSessionStartTime () + getJwtExp () - getJwtNbf () - getEpochSeconds ())
  : 0)

/**
 * reset user form saved states
 * @param commit    function to be called for Vuex mutations
 */
function resetFormSavedStates (commit) {
  commit ('create/resetState', undefined, {root: true})
  commit ('filter/resetState', undefined, {root: true})
}

export default {
  namespaced: true,

  state: {
    // the user has a valid JWT token
    isActive: isActive (),
    // the user JWT is not restricted for specific operations
    isAuthenticated: isAuthenticated (),
    // the user JWT is restricted for specific operations
    operationMode: getJwtOpMode (),
    jwtString: getJwtTokenString (),

    userLoginData: {},
    userPermissions: [],
    clientPermissions: [],
    visibleClients: [],

    effectiveUserData: {},
    effectiveUserPermissions: [],
    effectiveUserClientPermissions: [],
    effectiveVisibleClients: [],

    effectiveClientData: {},

    ttl: getTtl (),
    sessionStartTime: getSessionStartTime (),

    lastApiAccessTime: 0
  },

  getters: {
    userName: state => (
      state.isActive ? state.effectiveUserData.name || getJwtSub () : ''
    ),

    userNameUriEncoded: (state, getters) => {
      return encodeURI (getters.userName)
    },

    userDisplayName: state => ({
      userDisplayName: state.userLoginData?.displayName || 'unknown',
      effectiveUserDisplayName: state.effectiveUserData?.displayName
    }),

    userId: (state) => ({
      userId: state.userLoginData?.id || 0,
      effectiveUserId: state.effectiveUserData?.id || 0
    }),

    clientName: (state, getters) => ({
      clientName: state.userLoginData?.clientName || '',
      effectiveClientName: getters.isUserImpersonated
        ? state.effectiveUserData.clientName || ''
        : getters.isClientImpersonated
          ? state.effectiveClientData.name || ''
          : ''
    }),

    actingClientType: (state, getters) => {
      if (getters.isUserImpersonated) {
        return state.effectiveUserData.clientType || ''
      } else if (getters.isClientImpersonated) {
        return state.effectiveClientData.type || ''
      } else {
        return state.userLoginData?.clientType || ''
      }
    },

    clientType: (state) => {
      return state.userLoginData?.clientType
    },

    actingClientName: (state, getters) => {
      const {clientName, effectiveClientName} = getters.clientName
      return effectiveClientName || clientName
    },

    clientId: (state, getters) => ({
      clientId: state.userLoginData?.clientId || 0,
      effectiveClientId: getters.isUserImpersonated
        ? state.effectiveUserData?.clientId || 0
        : getters.isClientImpersonated
          ? state.effectiveClientData?.id || 0
          : 0
    }),

    actingClientId: (state, getters) => {
      const {clientId, effectiveClientId} = getters.clientId
      return effectiveClientId || clientId
    },

    isClientless: (state, getters) => {
      const clientId = getters.actingClientId
      return clientId === null || clientId === 0
    },

    hasClient: (state, getters) => {
      return getters.isUserImpersonated
        ? !!state.effectiveUserData.clientId
        : getters.isClientImpersonated
          ? !!state.effectiveClientData.id
          : !!state.userLoginData && !!state.userLoginData.clientId
    },

    jwtIssuer: state => (
      state.isActive && getJwtIss ()
    ),

    jwtIssuerUriEncoded: (state, getters) => {
      return encodeURI (getters.jwtIssuer)
    },

    shouldRefresh: state => (
      state.isAuthenticated && (state.ttl > 0) &&
        (state.ttl < ttlSecondsForUserReaction)
    ),

    isAutoRefreshAllowed: (state, getters) => (
      getters.shouldRefresh &&
      ((getEpochSeconds () - state.lastApiAccessTime) < apiAccessTolerance)
    ),

    // client/user permissions (strings are taken from
    //    de.knipp.coregateway.model.cm.UserPermission and
    //    de.knipp.coregateway.model.cm.ClientPermission)
    mayManageAllEntities: (state, getters) =>
      getters.permissions.includes ('ManageAllEntities'),

    mayManageSubEntities: (state, getters) =>
      getters.permissions.includes ('ManageSubEntities'),

    mayViewSubEntitiesObjects: (state, getters) =>
      getters.permissions.includes ('ViewSubClientObjects'),

    mayViewAllObjects: (state, getters) =>
      getters.permissions.includes ('ViewAllObjects'),

    mayManageOwnClient: (state, getters) =>
      getters.permissions.includes ('ManageOwnClient'),

    isUserImpersonated: state => !!state.effectiveUserData.name,
    isClientImpersonated: state => !!state.effectiveClientData.name,

    isImpersonated: (state, getters) => (
      getters.isUserImpersonated || getters.isClientImpersonated
    ),

    mayViewOnlyOwnObjects: (state, getters) =>
      getters.permissions.includes ('ViewOwnObjects') &&
      !getters.permissions.includes ('ViewAllObjects') &&
      !getters.permissions.includes ('ViewSubClientObjects'),

    mayViewClient: (state) => {
      return function (id) {
        if (state.effectiveVisibleClients?.length) {
          return state.effectiveVisibleClients.some (c => c.id === id)
        } else {
          return state.visibleClients.some (c => c.id === id)
        }
      }
    },

    isSameClient: (state, getters) => {
      return function (id) {
        return getters.actingClientId === id
      }
    },

    permissions: (state, getters) => (
      getters.isUserImpersonated
        ? state.effectiveUserPermissions
        : state.userPermissions
    ),

    hasAnyOfPermissions: (state, getters) => {
      /**
       * checks if any of specified permissions are set by acting user
       *
       * @param permissions {Array}         permissions to check
       * @return {Boolean}                  {@code true} if any of specified
       *                                    permissions is set by acting user,
       *                                    {@code false} otherwise
       */
      return permissions =>
        permissions.some (p => getters.permissions.includes (p))
    },

    hasNoneOfPermissions: (state, getters) => {
      /**
       * checks if none of the specified permissions are set for the acting user
       *
       * @param permissions {Array}         permissions to check
       * @return {Boolean}                  {@code true} if none of the
       *                                    specified permissions is set for the
       *                                    acting user,
       *                                    {@code false} otherwise
       */
      return permissions =>
        !permissions.some (p => getters.permissions.includes (p))
    },

    hasAllOfPermissions: (state, getters) => {
      /**
       * checks if all of specified permissions are set by acting user
       *
       * @param permissions {Array}         permissions to check
       * @return {Boolean}                  {@code true} if all of specified
       *                                    permissions are set by acting user,
       *                                    {@code false} otherwise
       */
      return permissions =>
        permissions.every (p => getters.permissions.includes (p))
    },

    mayManageObject (state, getters) {
      /**
       * checks if the acting user is allowed to manage an object with the
       * client id in the parameter
       *
       * @param objectClientId {String}     client id of the object owner
       * @return {Boolean}                  {@code true} if the acting user may
       *                                    manage the object,
       *                                    {@code false} otherwise
       */
      return objectClientId => {
        return getters.permissions.includes ('ManageAllObjects') ||
          (getters.permissions.includes ('ManageOwnObjects') &&
            getters.actingClientId === objectClientId) ||
          (getters.permissions.includes ('ManageSubClientObjects') &&
            !!state.visibleClients.find (
              client => client.id === objectClientId))
      }
    },

    mayViewObject (state, getters) {
      /**
       * check if the acting user is allowed to view an object that belongs
       * to the client with the given ID
       *
       * @param objectClientId {String}     client id of the object owner
       * @return {Boolean}                  {@code true} if the acting user may
       *                                    view the object, {@code false}
       *                                    otherwise
       */
      return objectClientId => {
        return getters.permissions.includes ('ViewAllObjects') ||
          (getters.permissions.includes ('ViewOwnObjects') &&
            getters.actingClientId === objectClientId) ||
          (getters.permissions.includes ('ViewSubClientObjects') &&
            !!state.visibleClients.find (
              client => client.id === objectClientId))
      }
    },

    mayManageForeignObjects (state, getters) {
      /**
       * checks if the acting user is allowed to manage objects other than
       * his own objects
       *
       * @return {Boolean}                  {@code true} if the acting user may
       *                                    manage the foreign objects,
       *                                    {@code false} otherwise
       */
      return getters.permissions.includes ('ManageAllObjects') ||
        getters.permissions.includes ('ManageSubClientObjects')
    },

    mayManageAllObjects (state, getters) {
      /**
       * checks if the acting user is allowed to manage all objects
       *
       * @return {Boolean}                  {@code true} if the acting user may
       *                                    manage all objects,
       *                                    {@code false} otherwise
       */
      return getters.permissions.includes ('ManageAllObjects')
    },

    hasSubClients (state) {
      return state.visibleClients?.length > 1
    }
  },

  mutations: {
    updateTtl (state) {
      state.ttl = getTtl ()
    },

    updateAuthentication (state) {
      state.isActive = isActive ()
      state.isAuthenticated = isAuthenticated ()
      state.operationMode = getJwtOpMode ()
      state.jwtString = getJwtTokenString ()
      state.sessionStartTime = getEpochSeconds ()
      setSessionStartTime (state.sessionStartTime)
    },

    updateLastApiAccessTime (state) {
      state.lastApiAccessTime = getEpochSeconds ()
    },

    setUserLoginData (state, userLoginData) {
      state.userLoginData = userLoginData
    },

    setPermissions (state, permissions) {
      state.userPermissions = permissions.userPerms
      state.clientPermissions = permissions.clientPerms
    },

    setVisibleClients (state, visibleClients) {
      state.visibleClients = visibleClients
    },

    setEffectiveVisibleClients (state, visibleClients) {
      state.effectiveVisibleClients = visibleClients
    },

    setEffectiveUserData (state, userData) {
      state.effectiveUserData = userData
    },

    setEffectiveUserPermissions (state, permissions) {
      state.effectiveUserPermissions = permissions.userPerms
      state.effectiveUserClientPermissions = permissions.clientPerms
    },

    setEffectiveClientData (state, clientData) {
      state.effectiveClientData = clientData
    }
  },

  actions: {
    // Perform VueAuthenticate login using Vuex actions
    async login ({state, commit, dispatch}, {userName, password}) {
      let success = false
      let userLoginData = {}

      let permissions = {
        userPerms: [],
        clientPerms: []
      }

      let visibleClients = []

      try {
        const request = vueAuthInstance.login ({
          request: {
            op: 'login'
          },
          username: userName,
          password: password,
          totpSecret: localStorage.getItem (TOTP_SECRET_KEY)
        });

        ({success} = await dispatch (
          'request/handleRequest',
          {
            request,
            cb: data => {
              userLoginData = data.userLoginData
            }
          }, {root: true}))

        commit ('updateAuthentication')
        commit ('updateTtl')

        const isOperationModeRestricted = !!state.operationMode

        if (success && !isOperationModeRestricted) {
          const perms = await loadPermissions (dispatch, [userName])
          if (perms) {
            permissions = perms
          } else {
            success = false
          }
        }

        if (success) {
          const vc = await loadVisibleClients (dispatch)
          if (vc) {
            visibleClients = vc
          } else {
            success = false
          }
        }
      } catch (error) {
        console.log ('handling notification at auth.js ', error)
        success = false
        commit (
          'notification/setError',
          `unexpected error occurred ('${error.message}')`, {root: true})
      }

      commit ('setUserLoginData', userLoginData)
      commit ('setPermissions', permissions)
      commit ('setVisibleClients', visibleClients)

      return success
    },

    async totpLogin ({commit, dispatch}, {validationCode, saveTotp}) {
      let success = false

      const totpLoginData = await fetchData (dispatch, 'totpLogin', {
        validationCode,
        saveTotp
      })

      commit ('updateAuthentication')
      commit ('updateTtl')

      success = !!totpLoginData?.userLoginData

      if (success) {
        commit ('setUserLoginData', totpLoginData.userLoginData)

        if (saveTotp) {
          localStorage.setItem (TOTP_SECRET_KEY, totpLoginData.totpSecret)
        }

        const permissions =
          await loadPermissions (dispatch, [getJwtSub ()])

        if (permissions) {
          commit ('setPermissions', permissions)
        } else {
          success = false
        }

        const visibleClients = await loadVisibleClients (dispatch)

        if (visibleClients) {
          commit ('setVisibleClients', visibleClients)
        } else {
          success = false
        }
      } else {
        if (jsonService.getResultCode (totpLoginData) ===
          'error/security/authenticationtimeout') {
          dispatch ('logout')
        }
      }

      return success
    },

    async impersonateClient ({commit, dispatch}, clientId) {
      const data =
        await fetchData (dispatch, 'impersonate', {effClientId: clientId})

      if (!jsonService.isSuccess (data)) {
        return false
      }

      if (data) {
        resetFormSavedStates (commit)
      }

      return !!data
    },

    async impersonateUser ({commit, dispatch}, userId) {
      let success = true
      let userData = {}
      let permissions = {
        userPerms: [],
        clientPerms: []
      }

      let visibleClients = []

      try {
        const data =
          await fetchData (dispatch, 'impersonate', {effUserId: userId})

        userData = data.userLoginData || userData

        if (userData.name) {
          const perms = await loadPermissions (dispatch, [userData.name])

          if (perms) {
            permissions = perms
          } else {
            success = false
          }

          const vc = await loadVisibleClients (dispatch)

          if (vc) {
            visibleClients = vc
          } else {
            success = false
          }
        }
      } catch (error) {
        console.log ('handling notification at auth.js ', error)
        success = false
        commit (
          'notification/setError',
          `unexpected error occurred ('${error.message}')`, {root: true})
      }

      commit ('setEffectiveUserData', userData)
      commit ('setEffectiveUserPermissions', permissions)
      commit ('setEffectiveVisibleClients', visibleClients)

      resetFormSavedStates (commit)

      return success
    },

    /**
     * return to the original user after ending of impersonation
     *
     * @param commit                the function to be used to perform state mutations
     * @param dispatch              the function to be used to call Vuex actions
     * @returns {Promise<void>}     nothing
     */
    async recover ({commit, dispatch}) {
      await fetchData (dispatch, 'return')
      commit ('setEffectiveUserData', {})
      commit ('setEffectiveUserPermissions', {
        userPerms: [],
        clientPerms: []
      })
      commit ('setEffectiveClientData', {})
      commit ('setEffectiveVisibleClients', [])

      resetFormSavedStates (commit)

      const vc = await loadVisibleClients (dispatch)
      commit ('setVisibleClients', vc)
    },

    async logout ({commit}) {
      try {
        await vueAuthInstance.logout ()
      } catch (e) {
        if (e.message !== 'There is no currently authenticated user') {
          console.log (e.msg)
        }
      }

      commit ('updateAuthentication')
      commit ('setUserLoginData', {})
      commit ('setPermissions', {
        userPerms: [],
        clientPerms: []
      })
      commit ('setEffectiveUserData', {})
      commit ('setEffectiveUserPermissions', {
        userPerms: [],
        clientPerms: []
      })
      commit ('setEffectiveClientData', {})
      commit ('setVisibleClients', [])
      commit ('setEffectiveVisibleClients', [])

      resetFormSavedStates (commit)
    },

    async reloadVisibleClients ({commit, dispatch, getters}) {
      const vc = await loadVisibleClients (dispatch)

      if (getters.isUserImpersonated) {
        commit ('setEffectiveVisibleClients', vc)
      } else {
        commit ('setVisibleClients', vc)
      }
    }
  }
}

async function fetchData (dispatch, op, params, cb) {
  return dispatch ('request/fetchData', {op, params, cb}, {root: true})
}

async function loadPermissions (dispatch, userNames) {
  const data = await fetchData (dispatch, 'user/perms', {names: userNames})
  return data?.userPermissionData[0]
}

async function loadVisibleClients (dispatch) {
  const data = await fetchData (dispatch, 'client/list/visible')
  return data?.clients
}
