import _cloneDeep from 'lodash/cloneDeep'

import {getDefaultCreateParams as getDefaultContactData}
  from '@/app/pages/Contact/constants'

import {getDefaultCreateParams as getDefaultDomainData}
  from '@/app/pages/Domain/constants'

import {getDefaultCreateParams as getDefaultHostData}
  from '@/app/pages/Host/constants'

import {getDefaultCreateParams as getDefaultZoneData}
  from '@/app/pages/Zone/components/constants'

import {getDefaultCreateParams as getDefaultRegistryData}
  from '@/app/pages/Configuration/components/constants'

/**
 * get default objects creation state
 *
 * @returns {Object}      default objects creation state
 */
function getDefaultState () {
  return {
    contactCreateData: getDefaultContactData (),
    domainCreateData: getDefaultDomainData (),
    domainCloneData: getDefaultDomainData (),
    clonedDomainDataFor: null,
    domainCreationInProgress: false,
    hostCreateData: getDefaultHostData (),
    zoneCreateData: getDefaultZoneData (),
    registryCreateData: getDefaultRegistryData (),
    unsavedChangesDialog: false,
    unsavedChangesCB: null,
    tariffCreateData: ''
  }
}

export default {
  namespaced: true,

  state: getDefaultState (),

  getters: {
    getContactCreateData (state) {
      return _cloneDeep (state.contactCreateData)
    },

    getDomainCreateData (state) {
      return _cloneDeep (state.domainCreateData)
    },

    getDomainCloneData (state) {
      return _cloneDeep (state.domainCloneData)
    },

    getClonedDomainDataFor (state) {
      return state.clonedDomainDataFor
    },

    getHostCreateData (state) {
      return _cloneDeep (state.hostCreateData)
    },

    getZoneCreateData (state) {
      return _cloneDeep (state.zoneCreateData)
    },

    getRegistryCreateData (state) {
      return _cloneDeep (state.registryCreateData)
    },

    getTariffCreateData (state) {
      return _cloneDeep (state.tariffCreateData)
    },

    isDomainCreationInProgress (state) {
      return state.domainCreationInProgress
    }
  },

  mutations: {
    resetState (state) {
      Object.assign (state, getDefaultState ())
    },

    setContactCreateData (state, data) {
      state.contactCreateData = _cloneDeep (data)
    },

    setDomainCreateData (state, data) {
      state.domainCreateData = _cloneDeep (data)
    },

    setDomainCloneData (state, data) {
      state.domainCloneData = _cloneDeep (data)
    },

    setClonedDomainDataFor (state, data) {
      state.clonedDomainDataFor = _cloneDeep (data)
    },

    setHostCreateData (state, data) {
      state.hostCreateData = _cloneDeep (data)
    },

    setZoneCreateData (state, data) {
      state.zoneCreateData = _cloneDeep (data)
    },

    setRegistryCreateData (state, data) {
      state.registryCreateData = _cloneDeep (data)
    },

    setTariffCreateData (state, data) {
      state.tariffCreateData = _cloneDeep (data)
    },

    setUnsavedChangesDialog (state, data) {
      state.unsavedChangesDialog = _cloneDeep (data)
    },

    setUnsavedChangesCB (state, cb) {
      state.unsavedChangesCB = cb
    },

    setDomainCreationInProgress (state, data) {
      state.domainCreationInProgress = data
    }
  }
}
