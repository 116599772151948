import BaseConstraint from './BaseConstraint'

/**
 * constraint, which ensure that the user's client has one
 * of the specified types
 */
export default class ClientTypeConstraint extends BaseConstraint {
  /**
   * create new constraint, which will check whether the user's client has one
   * of the specified types
   *
   * @param types                   the client types for which access shall be
   *                                granted
   * @param emptyTypePermitted      {@code true} if access shall also be granted
   *                                for client-less users; {@code false} by
   *                                default
   */
  constructor (types, emptyTypePermitted = false) {
    super ({
      check () {
        return this.isAuthenticated () && this.matchAnyClientType ()
      }
    })

    this.types = types
    this.emptyTypePermitted = emptyTypePermitted
  }

  /**
   * check whether the user client type match any of specified types
   *
   * @return {boolean}    {@code true} if the user's client has one
   *                      of the specified types
   */
  matchAnyClientType () {
    let match = false
    if (this.emptyTypePermitted && this.user.clientType === '') {
      match = true
    }

    for (const type of this.types) {
      if (this.user.clientType === type) {
        match = true
      }
    }

    return match
  }
}
