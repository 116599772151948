import Vue from 'vue'

import Vuetify from 'vuetify/lib'

import {preset} from './preset'

// ripple directive is used internally by vuetify and has to be imported
import {Ripple} from 'vuetify/lib/directives'

Vue.use (Vuetify, {
  directives: {
    Ripple
  }
})

// Vue.use (Vuetify, {
export default new Vuetify ({
  preset
})
