/**
 * basic constraint class
 */
export default class BaseConstraint {
  /**
   * the constructor
   *
   * @param check {Function}      the function, which will be used to check
   *                              if the constraint is satisfied for current
   *                              {@code user}
   */
  constructor ({check}) {
    // will be set in {@code isSatisfied}
    this.user = null

    // store the logic implementation function to be used in
    // {@code isSatisfied}
    this.check = check
  }

  /**
   * checks whether the user is authenticated (see store 'auth/isAuthenticated')
   *
   * @return    truthy value if the user is authenticated, falsy otherwise
   */
  isAuthenticated () {
    return this.user?.isAuthenticated
  }

  /**
   * checks whether the constraint is satisfied for the given user
   *
   * @param user      user (set of store properties) to be checked
   *
   * @return {*}      truthy value if the constraint is satisfied for the given
   *                  user, falsy otherwise
   */
  isSatisfied (user) {
    this.user = user

    return this.check ()
  }
}
