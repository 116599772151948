export default {
  namespaced: true,

  state: {
    drawerVisible: true,
    windowHeight: 0,
    contentHeight: 0
  },

  getters: {
    isDrawerVisible (state) {
      return state.drawerVisible
    },

    hasVerticalScrollbar (state) {
      return state.contentHeight > state.windowHeight
    }
  },

  mutations: {
    setDrawerVisible (state, data) {
      state.drawerVisible = data
    },

    setWindowHeight (state, data) {
      state.windowHeight = data
    },

    setContentHeight (state, data) {
      state.contentHeight = data
    }
  },

  actions: {
    updateContentHeight (context, data) {
      setTimeout (function () {
        const height = typeof data !== 'undefined'
          ? data
          : window.document.documentElement.scrollHeight

        context.commit ('setContentHeight', height)
      }, 1000)
    }
  }
}
