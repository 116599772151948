// Icon font CSS definitions
import 'material-design-icons/iconfont/material-icons.css'
// custom icons
import {icons as customIcons} from './icons'

// Styles
import '@/app/scss/main.scss'

export const preset = {
  theme: {
    themes: {
      light: {
        primary: '#688012',
        secondary: '#497a8a',
        accent: '#606b7d'
      }
    }
  },
  icons: {
    iconfont: 'md',
    values: customIcons
  }
}
