/**
 * Format the given client to a consistent name representation
 *
 * @param {Object} client     client contatining properties {name, id}
 *
 * @returns {String}          the client name and id formatted
 */
export const formatClient = ({name, id}) => {
  return `${name} (CORE-${id})`
}
