import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

import {
  faFile,
  faFileAlt,
  faFileArchive,
  faFileCode,
  faFileCsv,
  faFilePdf
} from '@fortawesome/free-solid-svg-icons'

const faIconDefinition = icon => ({
  component: FontAwesomeIcon,
  props: {
    icon
  }
})

const icons = {
  archive: faIconDefinition (faFileArchive),
  csv: faIconDefinition (faFileCsv),
  generic: faIconDefinition (faFile),
  html: faIconDefinition (faFileCode),
  pdf: faIconDefinition (faFilePdf),
  txt: faIconDefinition (faFileAlt),
  xml: faIconDefinition (faFileCode)
}

export {icons}
