import BaseConstraint from './BaseConstraint'

/**
 * the constraint, checking if the user is NOT authenticated
 */
export default new BaseConstraint ({
  /**
   * checks whether the user is NOT authenticated
   *
   * @return {Boolean|truthy|*}   {@code true} if the user is NOT authenticated,
   *                              {@ false} otherwise
   */
  check () {
    return !this.isAuthenticated ()
  }
})
